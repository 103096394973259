import axios from "./base-axios.js";

export const tokenless = "tokenless";

export const baseGet = async (url, headers) => {
  return axios
    .get(url, { headers })
    .then((response) => response)
    .catch((err) => {
      const message = err.response
        ? err.response.data
          ? err.response.data.message
          : err.message
        : err.message;
      throw message;
    });
}

export const Get = async (url, additionalHeaders, queryParams) => {
  const headers = {};
  const params = {};

  if (additionalHeaders && Object.keys(additionalHeaders).length > 0)
    Object.keys(additionalHeaders).forEach((header) => {
      headers[header] = additionalHeaders[header];
    });

  if (queryParams && Object.keys(queryParams).length > 0)
    Object.keys(queryParams).forEach((param) => {
      params[param] = queryParams[param];
    });

  return axios
    .get(url, { headers, params })
    .then((response) => {
      if (response.data.status !== 0)
        throw new Error(response.data.result.message);
      return response.data.result.data;
    })
    .catch((err) => {
      const message = err.response
        ? err.response.data
          ? err.response.data.message
          : err.message
        : err.message;
      throw message;
    });
};

export const Post = async (url, body, additionalHeaders) => {
  const headers = {};

  if (additionalHeaders && Object.keys(additionalHeaders).length > 0)
    Object.keys(additionalHeaders).forEach((header) => {
      headers[header] = additionalHeaders[header];
    });

  return axios
    .post(url, JSON.stringify(body), { headers })
    .then((response) => {
      if (response.data.status !== 0)
        throw new Error(response.data.result.message);
      return response.data.result.data;
    })
    .catch((err) => {
      const message = err.response
        ? err.response.data
          ? err.response.data.message
          : err.message
        : err.message;
      throw message;
    });
};

export const Put = async (url, body, additionalHeaders) => {
  const headers = {};

  if (additionalHeaders && Object.keys(additionalHeaders).length > 0)
    Object.keys(additionalHeaders).forEach((header) => {
      headers[header] = additionalHeaders[header];
    });

  return axios
    .put(url, JSON.stringify(body), { headers })
    .then((response) => {
      if (response.data.status !== 0)
        throw new Error(response.data.result.message);
      return response.data.result.data;
    })
    .catch((err) => {
      const message = err.response
        ? err.response.data
          ? err.response.data.message
          : err.message
        : err.message;
      throw message;
    });
};

export const Delete = async (url, additionalHeaders) => {
  const headers = {};

  if (additionalHeaders && Object.keys(additionalHeaders).length > 0)
    Object.keys(additionalHeaders).forEach((header) => {
      headers[header] = additionalHeaders[header];
    });

  return axios
    .delete(url, { headers })
    .then((response) => {
      if (response.data.status !== 0)
        throw new Error(response.data.result.message);
      return response.data.result.data;
    })
    .catch((err) => {
      const message = err.response
        ? err.response.data
          ? err.response.data.message
          : err.message
        : err.message;
      throw message;
    });
};
