import { FETCH_INTEREST } from "../../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";

import { setInterests } from "../../../actions/cv/interest";
import { getAll } from "../../../../repositories/cv/interest";
import { setNotification } from "../../../actions/common";

const fetchInterests = (action$) =>
  action$.pipe(
    ofType(FETCH_INTEREST),
    switchMap(() =>
      from(getAll()).pipe(
        flatMap((resp) => of(setInterests(resp))),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

export default fetchInterests;
