import { Get, Post, Put, Delete } from "../../utils/base-api-request";
import { store } from "../../store/index";

const getContactsUrl = process.env.REACT_APP_BASE_API + "api/contact/all";
const getContactUrl = process.env.REACT_APP_BASE_API + "api/contact";
const removeContactUrl = process.env.REACT_APP_BASE_API + "api/contact";
const addContactUrl = process.env.REACT_APP_BASE_API + "api/contact";
const updateContactUrl = process.env.REACT_APP_BASE_API + "api/contact";

export const getAll = async () => {
  return await Get(
    getContactsUrl + "/" + store.getState().authentication.user.id
  );
};

export const get = async (id) => {
  return await Get(getContactUrl + "/" + id);
};

export const add = async (contact) => {
  if (contact["userId"] === undefined)
    contact["userId"] = store.getState().authentication.user.id;

  return await Post(addContactUrl, contact);
};

export const update = async (contact) => {
  if (contact["userId"] === undefined)
    contact["userId"] = store.getState().authentication.user.id;

  return await Put(updateContactUrl, contact);
};

export const remove = async (id) => {
  return await Delete(removeContactUrl + "/" + id);
};

export const lookupData = (contacts) => {
  let lookup = {};
  contacts.forEach(
    (contact) =>
      (lookup[contact.id] = contact.firstName + " " + contact.lastName)
  );

  return lookup;
};
