import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App/index";
import * as serviceWorker from "./serviceWorker";
import config from "./config";
import { ReduxProvider } from "./store";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Keycloak"

const app = (
  <ReactKeycloakProvider authClient={keycloak}>
    <ReduxProvider>
      <BrowserRouter basename={config.basename}>
        <App />
      </BrowserRouter>
    </ReduxProvider>
  </ReactKeycloakProvider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
