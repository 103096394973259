import { UPDATE_USER } from "../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import { setUserData } from "../../actions/authentication";
import { update } from "../../../repositories/accessManagement/user";
import { setNotification } from "../../actions/common";

const updateUser = (action$) =>
  action$.pipe(
    ofType(UPDATE_USER),
    switchMap(({ payload }) =>
      from(update(payload)).pipe(
        flatMap((data) =>
          of(
            setUserData(data)
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

export default updateUser;
