import { UPDATE_TRANSACTION } from "../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import { setTransactions } from "../../actions/transaction";
import { update, sortData } from "../../../repositories/portfolio/transaction";
import { setNotification } from "../../actions/common";

const updateTransaction = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_TRANSACTION),
    switchMap(({ payload }) =>
      from(update(payload.item)).pipe(
        flatMap((resp) =>
          of(
            setTransactions(
              updateTransactions(
                [...state$.value.dataSet.transactions],
                payload.index,
                resp
              )
            )
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const updateTransactions = (transactions, index, item) => {
  transactions[index] = item;
  return sortData(transactions);
};

export default updateTransaction;
