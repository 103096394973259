import { DELETE_TRANSACTION } from "../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import { setTransactions } from "../../actions/transaction";
import { remove } from "../../../repositories/portfolio/transaction";
import { setNotification } from "../../actions/common";

const deleteTransaction = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_TRANSACTION),
    switchMap(({ payload }) =>
      from(remove(state$.value.dataSet.transactions[payload].id)).pipe(
        flatMap(() =>
          of(
            setTransactions(
              removeTransaction([...state$.value.dataSet.transactions], payload)
            )
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const removeTransaction = (transactions, index) => {
  transactions.splice(index, 1);
  return transactions;
};

export default deleteTransaction;
