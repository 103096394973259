import { createCanBoundTo } from "@casl/react";
import { Ability, AbilityBuilder } from "@casl/ability";
import * as subjects from "../../constants/subjects";
import * as permissions from "./constants/permissions";
import { store } from "../../store/index";
import routes from "../../routes";
import { defineRulesForBilling } from "./features/billingAuth";
import { defineRulesForCompanies } from "./features/companyAuth";
import { defineRulesForContacts } from "./features/contactAuth";
import { defineRulesForContracts } from "./features/contractAuth";
import { defineRulesForOffers } from "./features/offerAuth";
import { defineRulesForOptionSet } from "./features/optionSetAuth";
import { defineRulesForPersonalInfo } from "./features/personalInfoAuth";
import { defineRulesForProfessionalInfo } from "./features/professionalInfoAuth";
import { defineRulesForTimesheets } from "./features/timesheetAuth";
import { defineRulesForAccessRoles } from "./features/accessAuth";
import { defineRulesForTransactions } from "./features/transactionAuth";
import { defineRulesForCv } from "./features/cvAuth";
import { defineRulesForCharges } from "./features/chargeAuth";
import { defineRulesForEvents } from "./features/eventAuth";
import { defineRulesForEcommerce } from "./features/EcommerceAuth";
import { defineRulesForTaskBoard } from "./features/taskBoardAuth";
import { deepMergeObjectProperties } from "../../utils/converter";
export * from "./features/billingAuth";
export * from "./features/companyAuth";
export * from "./features/contactAuth";
export * from "./features/contractAuth";
export * from "./features/offerAuth";
export * from "./features/optionSetAuth";
export * from "./features/personalInfoAuth";
export * from "./features/professionalInfoAuth";
export * from "./features/timesheetAuth";
export * from "./features/accessAuth";
export * from "./features/cvAuth";
export * from "./features/transactionAuth";
export * from "./features/chargeAuth";
export * from "./features/eventAuth";
export * from "./features/EcommerceAuth";
export * from "./features/taskBoardAuth"

const ability = new Ability([]);

// export const fetchRolesPermissions = (roles) => {
//   const result = [...roles];
//   if (!roles || roles.length === 0) return [];

//   roles.forEach((role) => {
//     // const role = predefinedRoles.find((role) => role.name === group);
//     const perms = [permissions.COMPANY_MENU, permissions.COMPANY_ADD];
//     if (perms.length > 0) result.push(...perms);
//   });

//   return [...new Set(result)];
// };

export const defineRulesFor = (permissions) => {
  const { can, rules } = new AbilityBuilder();
  defineRulesForBilling(can, permissions);
  defineRulesForCompanies(can, permissions);
  defineRulesForContacts(can, permissions);
  defineRulesForContracts(can, permissions);
  defineRulesForOffers(can, permissions);
  defineRulesForOptionSet(can, permissions);
  defineRulesForPersonalInfo(can, permissions);
  defineRulesForProfessionalInfo(can, permissions);
  defineRulesForTimesheets(can, permissions);
  defineRulesForAccessRoles(can, permissions);
  defineRulesForCv(can, permissions);
  defineRulesForTransactions(can, permissions);
  defineRulesForCharges(can, permissions);
  defineRulesForCharges(can, permissions);
  defineRulesForEvents(can, permissions);
  defineRulesForEcommerce(can, permissions);
  defineRulesForTaskBoard(can, permissions);
  return ability.update(rules);
};

//------------------------- Menu ------------------------------
export const getAuthorizedMenu = () => {
  const userPermissions = store.getState().authentication.user.permissions;
  let authorizedMenu = [];

  if (userPermissions.includes(permissions.ALL)) {
    authorizedMenu.push(subjects.ALL);
    return Array.from(new Set(authorizedMenu.map((item) => item)));
  }

  if (
    [permissions.DASHBOARD_MENU].some((perm) => userPermissions.includes(perm))
  )
    authorizedMenu.push({
      id: subjects.NAVIGATION,
      childrens: [{ id: subjects.DASHBOARD }],
    });

  if ([permissions.STATS_BILLS_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedMenu.push({
      id: subjects.NAVIGATION,
      childrens: [{ id: subjects.STATISTICS, childrens: [{ id: subjects.STATS_BILLS }] }]
    });
  if (
    [permissions.PERSONAL_INFO_MENU].some((perm) =>
      userPermissions.includes(perm)
    )
  )
    authorizedMenu.push({
      id: subjects.PROFILE,
      childrens: [{ id: subjects.PERSONALINFO }],
    });

  if (
    [permissions.TIMESHEET_MENU].some((perm) => userPermissions.includes(perm))
  )
    authorizedMenu.push({
      id: subjects.MANAGEMENT,
      childrens: [{ id: subjects.TIMESHEETS }],
    });
  if (
    [permissions.TASK_BOARD_MENU].some((perm) => userPermissions.includes(perm))
  )
    authorizedMenu.push({
      id: subjects.MANAGEMENT,
      childrens: [{ id: subjects.TASK_BOARD }],
    });
  if ([permissions.BILLING_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedMenu.push({
      id: subjects.MANAGEMENT,
      childrens: [{ id: subjects.BILLING }],
    });
  if (
    [permissions.CONTRACT_MENU].some((perm) => userPermissions.includes(perm))
  )
    authorizedMenu.push({
      id: subjects.MANAGEMENT,
      childrens: [{ id: subjects.CONTRACTS }],
    });


  if (
    [permissions.TRANSACTION_MENU].some((perm) =>
      userPermissions.includes(perm)
    )
  )
    authorizedMenu.push({
      id: subjects.PORTFOLIO,
      childrens: [{ id: subjects.TRANSACTIONS }],
    });

  if ([permissions.CHARGE_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedMenu.push({
      id: subjects.PORTFOLIO,
      childrens: [{ id: subjects.CHARGES }],
    });

  if ([permissions.CV_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedMenu.push({
      id: subjects.PROSPECTING,
      childrens: [{ id: subjects.CV }],
    });

  if ([permissions.COMPANY_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedMenu.push({
      id: subjects.PROSPECTING,
      childrens: [{ id: subjects.COMPANIES }],
    });

  if ([permissions.CONTACT_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedMenu.push({
      id: subjects.PROSPECTING,
      childrens: [{ id: subjects.CONTACTS }],
    });

  if ([permissions.OFFER_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedMenu.push({
      id: subjects.PROSPECTING,
      childrens: [{ id: subjects.OFFERS }],
    });
  if ([permissions.EVENTS_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedMenu.push({
      id: subjects.PLANNING,
      childrens: [{ id: subjects.EVENTS }],
    });

  if ([permissions.ROLE_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedMenu.push({
      id: subjects.ADMINISTRATION,
      childrens: [
        { id: subjects.ACCESSMANAGEMENT, childrens: [{ id: subjects.ROLES }] },
      ],
    });
  if ([permissions.USER_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedMenu.push({
      id: subjects.ADMINISTRATION,
      childrens: [
        { id: subjects.ACCESSMANAGEMENT, childrens: [{ id: subjects.USERS }] },
      ],
    });
  if (
    [permissions.OPTIONSET_MENU].some((perm) => userPermissions.includes(perm))
  )
    authorizedMenu.push({
      id: subjects.ADMINISTRATION,
      childrens: [
        { id: subjects.SETTINGS, childrens: [{ id: subjects.OPTIONSET }] },
      ],
    });

  if (
    [permissions.ECOMMERCE_MENU].some((perm) => userPermissions.includes(perm))
  )
    authorizedMenu.push({
      id: subjects.ADMINISTRATION,
      childrens: [
        {
          id: subjects.STOCKMANAGER,
          childrens: [{ id: subjects.CAMPAIGNS }, { id: subjects.PRODUCTS }],
        },
      ],
    });

  let output = [];
  deepMergeObjectProperties(authorizedMenu, output, "id");
  return Array.from(new Set(output.map((item) => item)));
};

//------------------------- Menu ------------------------------
export const getAuthorizedUrls = () => {
  const userPermissions = store.getState().authentication.user.permissions;
  let authorizedUrls = [];

  if (userPermissions.includes(permissions.ALL))
    return routes.map(i => i.id);

  if (
    [permissions.DASHBOARD_MENU].some((perm) => userPermissions.includes(perm))
  )
    authorizedUrls.push(subjects.DASHBOARD);

  if ([permissions.STATS_BILLS_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedUrls.push(subjects.STATS_BILLS);
  if (
    [permissions.PERSONAL_INFO_MENU].some((perm) =>
      userPermissions.includes(perm)
    )
  )
    authorizedUrls.push(subjects.PERSONALINFO);

  if (
    [permissions.PROFESSIONAL_INFO_MENU].some((perm) =>
      userPermissions.includes(perm)
    )
  )
    authorizedUrls.push(subjects.PROFESSIONALINFO);

  if ([permissions.CV_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedUrls.push(subjects.CV);

  if ([permissions.COMPANY_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedUrls.push(subjects.COMPANIES);

  if ([permissions.CONTACT_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedUrls.push(subjects.CONTACTS);

  if ([permissions.OFFER_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedUrls.push(subjects.OFFERS);

  if (
    [permissions.CONTRACT_MENU].some((perm) => userPermissions.includes(perm))
  )
    authorizedUrls.push(subjects.CONTRACTS);

  if (
    [permissions.TIMESHEET_MENU].some((perm) => userPermissions.includes(perm))
  )
    authorizedUrls.push(subjects.TIMESHEETS);

  if (
    [permissions.TASK_BOARD_MENU].some((perm) => userPermissions.includes(perm))
  )
    authorizedUrls.push(subjects.TASK_BOARD);

  if ([permissions.BILLING_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedUrls.push(subjects.BILLING);

  if (
    [permissions.OPTIONSET_MENU].some((perm) => userPermissions.includes(perm))
  )
    authorizedUrls.push(subjects.SETTINGS, subjects.OPTIONSET);

  if ([permissions.ROLE_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedUrls.push(subjects.ROLES);
  if ([permissions.USER_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedUrls.push(subjects.USERS);
  if (
    [permissions.TRANSACTION_MENU].some((perm) =>
      userPermissions.includes(perm)
    )
  )
    authorizedUrls.push(subjects.TRANSACTIONS);
  if ([permissions.CHARGE_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedUrls.push(subjects.CHARGES);

  if ([permissions.EVENTS_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedUrls.push(subjects.EVENTS);
  if (
    [permissions.ECOMMERCE_MENU].some((perm) => userPermissions.includes(perm))
  )
    authorizedUrls.push(subjects.CAMPAIGNS, subjects.PRODUCTS);

  return authorizedUrls;
};

export const Can = createCanBoundTo(ability);
