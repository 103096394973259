import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslations from "./Locales/en/translation_en.json";
import frTranslations from "./Locales/fr/translation_fr.json";

const resources = {
  en: { messages: enTranslations },
  fr: { messages: frTranslations },
};
const init_i18n = i18next.use(initReactI18next);
const i18n = (userLanguage) => {
  init_i18n.init({
    react: {
      wait: true,
    },
    resources: resources,
    lng: userLanguage,
    fallbackLng: userLanguage,
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
    ns: ["messages"],
    defaultNS: "messages",
    fallbackNS: [],
  });

  return init_i18n;
};

export default i18n;
