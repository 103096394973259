import { FETCH_PROFILE } from "../../../constants/actions-types";
import { mergeMap, flatMap, catchError, finalize } from "rxjs/operators";
import { of, from, merge } from "rxjs";
import { ofType } from "redux-observable";

import { setProfile } from "../../../actions/cv/profile";
import { getAll } from "../../../../repositories/cv/profile";
import { setNotification, setLoading } from "../../../actions/common";
import { dispatch } from "../../../../store";
const fetchProfile = (action$) =>
  action$.pipe(
    ofType(FETCH_PROFILE),
    mergeMap(() =>
      merge(
        of(setLoading(true)),
        from(getAll()).pipe(
          flatMap((resp) => of(setProfile(resp.length > 0 ? resp[0] : {}))),
          catchError((error) =>
            of(
              setNotification({
                isSuccess: false,
                message: "Error : " + error,
              })
            )
          ),
          finalize(() => dispatch(setLoading(false)))
        )
      )
    )
  );

export default fetchProfile;
