import { DISMISS_EVENT } from "../../constants/actions-types";
import { mergeMap, flatMap, catchError } from "rxjs/operators";
import { of, from, merge } from "rxjs";
import { ofType } from "redux-observable";
import { setNotification } from "../../actions/common";
import { dismissEvents } from "../../../repositories/planning/events";
import { setEvents } from "../../actions/planning";

const dismissEvs = (action$, state$) =>
  action$.pipe(
    ofType(DISMISS_EVENT),
    mergeMap(({ payload }) =>
      merge(
        from(dismissEvents(payload)).pipe(
          flatMap((resp) => of(setEvents(updateEvents({ ...state$.value.dataSet.events }, payload)))),
          catchError((error) =>
            of(
              setNotification({
                isSuccess: false,
                message: "Error : " + error,
              })
            )
          )
        )
      )
    )
  );

const updateEvents = (events, items) => {
  items.forEach(ev => {
    const result = events.data.filter(i => i.id === ev.id);
    result.forEach(r =>
      r.dismissed.push({ id: ev.id, start: new Date(ev.start), end: new Date(ev.end) })
    )
  });
  return events;
};

export default dismissEvs;
