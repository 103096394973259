import { SIGN_UP } from "../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import { register } from "../../../repositories/accessManagement/user";
import { setNotification } from "../../actions/common";
import { setSignUp } from "../..//actions/authentication";
import i18next from "i18next";

const signUp = (action$) =>
  action$.pipe(
    ofType(SIGN_UP),
    switchMap(({ payload }) =>
      from(
        register(
          payload.userName,
          payload.firstName,
          payload.lastName,
          payload.mail,
          payload.password,
        )
      ).pipe(
        flatMap(() =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t(
                "Account created successfully, you can log in with your credentials"
              ),
            }),
            setSignUp()
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

export default signUp;
