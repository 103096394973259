import { FETCH_EVENT } from "../../constants/actions-types";
import { mergeMap, flatMap, catchError, finalize } from "rxjs/operators";
import { of, from, merge } from "rxjs";
import { ofType } from "redux-observable";
import { setLoading, setNotification } from "../../actions/common";
import { addDays, getStartOfDay, getTime } from "../../../utils/date";
import { getByDatesAll } from "../../../repositories/planning/events";
import { setEvents } from "../../actions/planning";
import { dispatch } from "../../../store";

const startDate = addDays(getStartOfDay(), - 1);
const endDate = addDays(getStartOfDay(), 2);

const fetchEvents = (action$) =>
  action$.pipe(
    ofType(FETCH_EVENT),
    mergeMap(() =>
      merge(
        of(setLoading(true)),
        from(getByDatesAll(getTime(startDate),
          getTime(endDate))).pipe(
            flatMap((resp) => of(setEvents({ start: startDate, end: endDate, data: resp }))),
            catchError((error) =>
              of(
                setNotification({
                  isSuccess: false,
                  message: "Error : " + error,
                })
              )
            ),
            finalize(() => dispatch(setLoading(false)))
          )
      )
    )
  );

export default fetchEvents;
