import { Get, Post, Put, Delete } from "../../utils/base-api-request";
import { store } from "../../store/index";

const getMissionsUrl = process.env.REACT_APP_BASE_API + "api/mission/all";
const getMissionUrl = process.env.REACT_APP_BASE_API + "api/mission";
const removeMissionUrl = process.env.REACT_APP_BASE_API + "api/mission";
const addMissionUrl = process.env.REACT_APP_BASE_API + "api/mission";
const updateMissionUrl = process.env.REACT_APP_BASE_API + "api/mission";

export const sortData = (missions) => {
  return missions.sort((a, b) => {
    if (a.isPresentMission && !b.isPresentMission) return -1;
    else if (!a.isPresentMission && b.isPresentMission) return 1;
    else if (!a.isPresentMission && !b.isPresentMission) {
      if (a.endYear === b.endYear) {
        if (a.endMonth === b.endMonth) {
          if (a.startYear === b.startYear) {
            if (a.startMonth === b.startMonth || a.startMonth > b.startMonth) {
              return -1;
            } else {
              return 1;
            }
          } else if (a.startYear > b.startYear) return -1;
          else return 1;
        } else if (a.endMonth > b.endMonth) return -1;
        else return 1;
      } else if (a.endYear > b.endYear) return -1;
      else return 1;
    } else {
      if (a.endMonth === b.endMonth) {
        if (a.startYear === b.startYear) {
          if (a.startMonth === b.startMonth || a.startMonth > b.startMonth) {
            return -1;
          } else {
            return 1;
          }
        } else if (a.startYear > b.startYear) return -1;
        else return 1;
      } else if (a.endMonth > b.endMonth) return -1;
      else return 1;
    }
  });
};

export const getAll = async () => {
  const response = await Get(
    getMissionsUrl + "/" + store.getState().authentication.user.id
  );
  return sortData(response);
};

export const get = async (id) => {
  return await Get(getMissionUrl + "/" + id);
};

export const add = async (mission) => {
  if (mission["userId"] === undefined)
    mission["userId"] = store.getState().authentication.user.id;

  return await Post(addMissionUrl, mission);
};

export const update = async (mission) => {
  if (mission["userId"] === undefined)
    mission["userId"] = store.getState().authentication.user.id;

  return await Put(updateMissionUrl, mission);
};

export const remove = async (id) => {
  return await Delete(removeMissionUrl + "/" + id);
};

export const lookupData = (missions) => {
  let lookup = {};
  missions.forEach((mission) => (lookup[mission.id] = mission.project));

  return lookup;
};
