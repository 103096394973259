import { DELETE_OPTIONSET } from "../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setOptionSet } from "../../actions/optionSet";
import { remove } from "../../../repositories/optionSet";
import { setNotification } from "../../actions/common";

const deleteOptionSet = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_OPTIONSET),
    switchMap(({ payload }) =>
      from(remove(payload)).pipe(
        flatMap(() =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully deleted"),
            }),
            setOptionSet(
              removeOption([...state$.value.configuration.optionSet], payload)
            )
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const removeOption = (options, index) => {
  options.splice(index, 1);
  return options;
};

export default deleteOptionSet;
