import { UPDATE_CERTIFICATION } from "../../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setCertifications } from "../../../actions/cv/certification";
import { update, sortData } from "../../../../repositories/cv/certification";
import { setNotification } from "../../../actions/common";

const updateCertification = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_CERTIFICATION),
    switchMap(({ payload }) =>
      from(update(payload.item)).pipe(
        flatMap((resp) =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully update"),
            }),
            setCertifications(
              updateCertifications(
                [...state$.value.dataSet.cv.certifications],
                payload.index,
                resp
              )
            )
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const updateCertifications = (certifications, index, item) => {
  certifications[index] = item;
  return sortData(certifications);
};

export default updateCertification;
