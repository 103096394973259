import { UPDATE_SKILL } from "../../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setSkills } from "../../../actions/cv/skill";
import { update } from "../../../../repositories/cv/skill";
import { setNotification } from "../../../actions/common";

const updateSkill = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_SKILL),
    switchMap(({ payload }) =>
      from(update(payload.item)).pipe(
        flatMap((resp) =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully update"),
            }),
            setSkills(
              updateSkills(
                [...state$.value.dataSet.cv.skills],
                payload.index,
                resp
              )
            )
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const updateSkills = (skills, index, item) => {
  skills[index] = item;
  return skills;
};

export default updateSkill;
