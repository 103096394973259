import { FETCH_DATA } from "../../constants/actions-types";
import { mergeMap } from "rxjs/operators";
import { of, merge } from "rxjs";
import { ofType } from "redux-observable";
import { fetchOptionSet } from "../../actions/optionSet";
// import { fetchCompanies } from "../../actions/company";
// import { fetchContacts } from "../../actions/contact";
import { fetchProfessionalInfo } from "../../actions/profile/professionalInfo";
import { fetchProjects } from "../../actions/reporting/project";
// import { fetchEvents } from "../../actions/planning";
// import { fetchTransactions } from "../../actions/transaction";

const fetchData = (action$) =>
  action$.pipe(
    ofType(FETCH_DATA),
    mergeMap(() =>
      merge(
        of(fetchOptionSet()),
        // of(fetchCompanies()),
        // of(fetchContacts()),
        of(fetchProfessionalInfo()),
        of(fetchProjects()),
        // of(fetchEvents()),
        // of(fetchTransactions())
      )
    )
  );

export default fetchData;
