import { DELETE_EVENT } from "../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { remove } from "../../../repositories/planning/events";
import { setNotification } from "../../actions/common";
import { fetchEvents } from "../../actions/planning";

const deleteEvent = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_EVENT),
    switchMap(({ payload }) =>
      from(remove(payload)).pipe(
        flatMap(() =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully deleted"),
            }),
            fetchEvents()
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

export default deleteEvent;
