import {
  SIGN_IN,
  SIGN_UP,
  SET_SIGN_UP,
  FETCH_USER,
  SET_USER_DATA,
  SIGNOUT,
  SET_USER_ABILITY,
  UPDATE_USER,
  UPDATE_USER_PICTURE,
  UPDATE_USER_ACTIVE,
  UPDATE_PARAMS,
  SET_PARAMS,
  SET_AUTH
} from "../../constants/actions-types";

export const signUp = (data) => ({
  type: SIGN_UP,
  payload: data,
});

export const signIn = (data) => ({
  type: SIGN_IN,
  payload: data,
});

export const setAuth = (data) => ({
  type: SET_AUTH,
  payload: data,
});

export const fetchUser = (data) => ({
  type: FETCH_USER,
  payload: data,
});

export const setUserData = (data, isLogin) => ({
  type: SET_USER_DATA,
  payload: { data, isLogin },
});

export const setUserAbility = (data) => ({
  type: SET_USER_ABILITY,
  payload: data,
});

export const setSignUp = (data) => ({
  type: SET_SIGN_UP,
  payload: data,
});

export const signOut = () => ({
  type: SIGNOUT,
  payload: false,
});

export const updateUser = (data) => ({
  type: UPDATE_USER,
  payload: data,
});

export const updateUserPicture = (data) => ({
  type: UPDATE_USER_PICTURE,
  payload: data,
});

export const updateActive = (data) => ({
  type: UPDATE_USER_ACTIVE,
  payload: data,
});

export const updateParams = (data) => ({
  type: UPDATE_PARAMS,
  payload: data,
});

export const setParams = (data) => ({
  type: SET_PARAMS,
  payload: data,
});

