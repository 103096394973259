import {
  FETCH_DATA,
  COLLAPSE_MENU,
  COLLAPSE_TOGGLE,
  FULL_SCREEN,
  FULL_SCREEN_EXIT,
  CHANGE_LAYOUT,
  NAV_CONTENT_LEAVE,
  NAV_COLLAPSE_LEAVE,
  SET_NOTIFICATION,
  SET_LOADING,
  SET_URL,
  SEND_MAIL,
} from "../../constants/actions-types";

export const fetchData = () => ({
  type: FETCH_DATA,
});

export const sendMail = (data) => ({
  type: SEND_MAIL,
  payload: data,
});

export const collapseMenu = () => ({
  type: COLLAPSE_MENU,
});

export const collapseToggle = (menu) => ({
  type: COLLAPSE_TOGGLE,
  payload: menu,
});

export const fullScreen = () => ({
  type: FULL_SCREEN,
});

export const fullScreenExit = () => ({
  type: FULL_SCREEN_EXIT,
});

export const changeLayout = (layout) => ({
  type: CHANGE_LAYOUT,
  payload: layout,
});

export const navContentLeave = () => ({
  type: NAV_CONTENT_LEAVE,
});

export const navCollapseLeave = (menu) => ({
  type: NAV_COLLAPSE_LEAVE,
  payload: menu,
});

export const setNotification = (data) => ({
  type: SET_NOTIFICATION,
  payload: data,
});

export const setLoading = (data) => ({
  type: SET_LOADING,
  payload: data,
});

export const setUrl = (data) => ({
  type: SET_URL,
  payload: data,
});

