import * as subjects from "../../../constants/subjects.js";
import * as permissions from "../constants/permissions.js";
// import { store } from "../../../store/index.js";

export const defineRulesForEcommerce = (can, roles) => {
  if ([permissions.ALL].some((role) => roles.includes(role))) {
    can(permissions.MANAGE, subjects.CAMPAIGNS);
    can(permissions.MANAGE, subjects.PRODUCTS);
  } else {
    roles.forEach((perm) => {
      can(perm, subjects.CAMPAIGNS);
      can(perm, subjects.PRODUCTS);
    });
  }
};

// export const canHandleRoles = () =>
//   store
//     .getState()
//     .authentication.user.ability.can(permissions.ROLE_MANAGE, subjects.ROLES);

// export const canHandleUsers = () =>
//   store
//     .getState()
//     .authentication.user.ability.can(permissions.USER_MANAGE, subjects.USERS);
