import { Get, Post, Put, Delete } from "../../utils/base-api-request";
import { store } from "../../store/index";
import { getTime } from "../../utils/date";

const getProfilesUrl = process.env.REACT_APP_BASE_API + "api/profile/all";
const getProfileUrl = process.env.REACT_APP_BASE_API + "api/profile";
const removeProfileUrl = process.env.REACT_APP_BASE_API + "api/profile";
const addProfileUrl = process.env.REACT_APP_BASE_API + "api/profile";
const updateProfileUrl = process.env.REACT_APP_BASE_API + "api/profile";

export const getAll = async () => {
  return await Get(
    getProfilesUrl + "/" + store.getState().authentication.user.id
  );
};

export const get = async (id) => {
  const profile = await Get(getProfileUrl + "/" + id);
  return infoDtoToInfoView(profile);
};

export const add = async (profile) => {
  if (profile["userId"] === undefined)
    profile["userId"] = store.getState().authentication.user.id;

  const response = await Post(addProfileUrl, infoViewToInfoDto(profile));
  return infoDtoToInfoView(response);
};

export const update = async (profile) => {
  if (profile["userId"] === undefined)
    profile["userId"] = store.getState().authentication.user.id;

  const response = await Put(updateProfileUrl, infoViewToInfoDto(profile));
  return infoDtoToInfoView(response);
};

export const remove = async (id) => {
  return await Delete(removeProfileUrl + "/" + id);
};

const infoDtoToInfoView = (item) => {
  return {
    ...item,
  };
};

const infoViewToInfoDto = (item) => {
  return {
    ...item,
    birthDate: getTime(item.birthDate),
  };
};
