import { ADD_SKILL } from "../../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setSkills } from "../../../actions/cv/skill";
import { add } from "../../../../repositories/cv/skill";
import { setNotification } from "../../../actions/common";

const addSkill = (action$, state$) =>
  action$.pipe(
    ofType(ADD_SKILL),
    switchMap(({ payload }) =>
      from(add(payload)).pipe(
        flatMap((resp) =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully added"),
            }),
            setSkills(addSkills([...state$.value.dataSet.cv.skills], resp))
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const addSkills = (skills, item) => {
  skills.push(item);
  return skills;
};

export default addSkill;
