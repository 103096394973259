import {
  FETCH_CERTIFICATION,
  SET_CERTIFICATION,
  ADD_CERTIFICATION,
  UPDATE_CERTIFICATION,
  DELETE_CERTIFICATION,
} from "../../../constants/actions-types";

export const fetchCertifications = () => ({
  type: FETCH_CERTIFICATION,
  payload: {},
});

export const setCertifications = (data) => ({
  type: SET_CERTIFICATION,
  payload: data,
});

export const addCertification = (data) => ({
  type: ADD_CERTIFICATION,
  payload: data,
});

export const updateCertification = (data) => ({
  type: UPDATE_CERTIFICATION,
  payload: data,
});

export const deleteCertification = (index) => ({
  type: DELETE_CERTIFICATION,
  payload: index,
});
