import { DELETE_OFFER } from "../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setOffers } from "../../actions/offer";
import { remove } from "../../../repositories/offer";
import { setNotification } from "../../actions/common";

const deleteOffer = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_OFFER),
    switchMap(({ payload }) =>
      from(remove(state$.value.dataSet.offers[payload].id)).pipe(
        flatMap(() =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully deleted"),
            }),
            setOffers(removeOffer([...state$.value.dataSet.offers], payload))
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const removeOffer = (offers, index) => {
  offers.splice(index, 1);
  return offers;
};

export default deleteOffer;
