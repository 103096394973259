import { DELETE_CERTIFICATION } from "../../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setCertifications } from "../../../actions/cv/certification";
import { remove } from "../../../../repositories/cv/certification";
import { setNotification } from "../../../actions/common";

const deleteCertification = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_CERTIFICATION),
    switchMap(({ payload }) =>
      from(remove(payload)).pipe(
        flatMap(() =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully deleted"),
            }),
            setCertifications(
              removeCertification(
                [...state$.value.dataSet.cv.certifications],
                payload
              )
            )
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const removeCertification = (certifications, id) => {
  return certifications.filter((cert) => cert.id !== id);
};

export default deleteCertification;
