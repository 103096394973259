import * as subjects from "../../../constants/subjects";
import * as permissions from "../constants/permissions";
import { store } from "../../../store/index.js";

export const defineRulesForCv = (can, roles) => {
  if ([permissions.ALL].some((role) => roles.includes(role))) {
    can(permissions.MANAGE, subjects.CV);
  } else {
    roles.forEach((perm) => {
      can(perm, subjects.CV);
    });
  }
};

export const canPrintCv = () =>
  store
    .getState()
    .authentication.user.ability.can(permissions.CV_PRINT, subjects.CV);
