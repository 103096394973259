import { UPDATE_TRAINING } from "../../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setTrainings } from "../../../actions/cv/training";
import { update, sortData } from "../../../../repositories/cv/training";
import { setNotification } from "../../../actions/common";

const updateTraining = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_TRAINING),
    switchMap(({ payload }) =>
      from(update(payload.item)).pipe(
        flatMap((resp) =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully update"),
            }),
            setTrainings(
              updateTrainings(
                [...state$.value.dataSet.cv.trainings],
                payload.index,
                resp
              )
            )
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const updateTrainings = (trainings, index, item) => {
  trainings[index] = item;
  return sortData(trainings);
};

export default updateTraining;
