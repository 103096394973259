import { FETCH_CERTIFICATION } from "../../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";

import { setCertifications } from "../../../actions/cv/certification";
import { getAll } from "../../../../repositories/cv/certification";
import { setNotification } from "../../../actions/common";

const fetchCertifications = (action$) =>
  action$.pipe(
    ofType(FETCH_CERTIFICATION),
    switchMap(() =>
      from(getAll()).pipe(
        flatMap((resp) => of(setCertifications(resp))),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

export default fetchCertifications;
