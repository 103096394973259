import * as subjects from "../../../constants/subjects.js";
import * as permissions from "../constants/permissions.js";
import { store } from "../../../store/index.js";

export const defineRulesForEvents = (can, roles) => {
  if ([permissions.ALL].some((role) => roles.includes(role))) {
    can(permissions.MANAGE, subjects.EVENTS);
  } else {
    roles.forEach((perm) => {
      can(perm, subjects.EVENTS);
    });
  }
};

export const canAddEvent = () =>
  store
    .getState()
    .authentication.user.ability.can(permissions.EVENTS_ADD, subjects.EVENTS);

export const canUpdateEvent = () =>
  store
    .getState()
    .authentication.user.ability.can(
      permissions.EVENTS_UPDATE,
      subjects.EVENTS
    );

export const canDeleteEvent = () =>
  store
    .getState()
    .authentication.user.ability.can(
      permissions.EVENTS_DELETE,
      subjects.EVENTS
    );
