import {
  ADD_EVENT,
  DELETE_EVENT,
  DISMISS_EVENT,
  FETCH_EVENT,
  SET_EVENT,
  UPDATE_EVENT,
} from "../../constants/actions-types";

export const fetchEvents = (data) => ({
  type: FETCH_EVENT,
  payload: data,
});

export const addEvent = (data) => ({
  type: ADD_EVENT,
  payload: data,
});

export const updateEvent = (data) => ({
  type: UPDATE_EVENT,
  payload: data,
});

export const dismissEvents = (data) => ({
  type: DISMISS_EVENT,
  payload: data,
});

export const setEvents = (data) => ({
  type: SET_EVENT,
  payload: data,
});

export const deleteEvent = (id) => ({
  type: DELETE_EVENT,
  payload: id,
});

