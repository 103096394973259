import { UPDATE_USER_PICTURE } from "../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import { setUserData } from "../../actions/authentication";
import { updateProfilePicture } from "../../../repositories/accessManagement/user";
import { setNotification } from "../../actions/common";

const updateUserPicture = (action$) =>
  action$.pipe(
    ofType(UPDATE_USER_PICTURE),
    switchMap(({ payload }) =>
      from(updateProfilePicture(payload.id, payload.name, payload.content)).pipe(
        flatMap((data) =>
          of(
            setUserData(data)
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

export default updateUserPicture;
