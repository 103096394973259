import { UPDATE_BANKINFO } from "../../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import { setProfessionalInfo } from "../../../actions/profile/professionalInfo";
import { updateBankInfo } from "../../../../repositories/profiles/professionalInfo";
import { setNotification } from "../../../actions/common";

const setBankInfo = (action$) =>
  action$.pipe(
    ofType(UPDATE_BANKINFO),
    switchMap(({ payload }) =>
      from(updateBankInfo(payload.id, payload.bankName, payload.bankNumber)).pipe(
        flatMap((resp) =>
          of(
            // setNotification({
            //   isSuccess: true,
            //   message: i18next.t("successfully update"),
            // }),
            setProfessionalInfo(resp)
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

export default setBankInfo;
