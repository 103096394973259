import {
  FETCH_MISSION,
  SET_MISSION,
  ADD_MISSION,
  UPDATE_MISSION,
  DELETE_MISSION,
} from "../../../constants/actions-types";

export const fetchMissions = () => ({
  type: FETCH_MISSION,
  payload: {},
});

export const setMissions = (data) => ({
  type: SET_MISSION,
  payload: data,
});

export const addMission = (data) => ({
  type: ADD_MISSION,
  payload: data,
});

export const updateMission = (data) => ({
  type: UPDATE_MISSION,
  payload: data,
});

export const deleteMission = (index) => ({
  type: DELETE_MISSION,
  payload: index,
});
