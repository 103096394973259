import { UPDATE_OPTIONSET } from "../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setOptionSet } from "../../actions/optionSet";
import { update } from "../../../repositories/optionSet";
import { setNotification } from "../../actions/common";

const updateOptionSet = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_OPTIONSET),
    switchMap(({ payload }) =>
      from(update(payload.item)).pipe(
        flatMap((resp) =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully update"),
            }),
            setOptionSet(
              updateOptions(
                [...state$.value.configuration.optionSet],
                payload.index,
                resp
              )
            )
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const updateOptions = (options, index, item) => {
  options[index] = item;
  return options;
};

export default updateOptionSet;
