import { combineEpics } from "redux-observable";
import fetchUser from "./authentication/fetchUser";
import fetchData from "./common/fetchData";
import sendMail from "./common/sendMail";
import signUp from "./authentication/signUp";
import signIn from "./authentication/signIn";
import updateParams from "./authentication/updateParams";
import updateUser from "./authentication/updateUser";
import updateUserPicture from "./authentication/updateUserPicture";
import fetchOptionSet from "./optionSet/fetchOptionSet";
import addOptionSet from "./optionSet/addOptionSet";
import updateOptionSet from "./optionSet/updateOptionSet";
import deleteOptionSet from "./optionSet/deleteOptionSet";
import fetchContacts from "./contact/fetchContacts";
import addContact from "./contact/addContact";
import updateContact from "./contact/updateContact";
import deleteContact from "./contact/deleteContact";
import fetchCompanies from "./company/fetchCompanies";
import addCompany from "./company/addCompany";
import updateCompany from "./company/updateCompany";
import deleteCompany from "./company/deleteCompany";
import fetchCompanySuggestions from "./company/fetchCompanySuggestions";
import fetchOffers from "./offer/fetchOffers";
import addOffer from "./offer/addOffer";
import updateOffer from "./offer/updateOffer";
import deleteOffer from "./offer/deleteOffer";
import fetchProfile from "./cv/profile/fetchProfile";
import addProfile from "./cv/profile/addProfile";
import updateProfile from "./cv/profile/updateProfile";
import deleteProfile from "./cv/profile/deleteProfile";
import fetchCertifications from "./cv/certification/fetchCertifications";
import addCertification from "./cv/certification/addCertification";
import updateCertification from "./cv/certification/updateCertification";
import deleteCertification from "./cv/certification/deleteCertification";
import fetchMissions from "./cv/mission/fetchMissions";
import addMission from "./cv/mission/addMission";
import updateMission from "./cv/mission/updateMission";
import deleteMission from "./cv/mission/deleteMission";
import fetchInterests from "./cv/interest/fetchInterests";
import addInterest from "./cv/interest/addInterest";
import updateInterest from "./cv/interest/updateInterest";
import deleteInterest from "./cv/interest/deleteInterest";
import fetchLanguages from "./cv/language/fetchLanguages";
import addLanguage from "./cv/language/addLanguage";
import updateLanguage from "./cv/language/updateLanguage";
import deleteLanguage from "./cv/language/deleteLanguage";
import fetchSkills from "./cv/skill/fetchSkills";
import addSkill from "./cv/skill/addSkill";
import updateSkill from "./cv/skill/updateSkill";
import deleteSkill from "./cv/skill/deleteSkill";
import fetchTrainings from "./cv/training/fetchTrainings";
import addTraining from "./cv/training/addTraining";
import updateTraining from "./cv/training/updateTraining";
import deleteTraining from "./cv/training/deleteTraining";
import fetchStudies from "./cv/study/fetchStudies";
import addStudy from "./cv/study/addStudy";
import updateStudy from "./cv/study/updateStudy";
import deleteStudy from "./cv/study/deleteStudy";
import fetchProfessionalInfo from "./profile/professionalInfo/fetchProfessionalInfo";
import addProfessionalInfo from "./profile/professionalInfo/addProfessionalInfo";
import updateProfessionalInfo from "./profile/professionalInfo/updateProfessionalInfo";
import deleteProfessionalInfo from "./profile/professionalInfo/deleteProfessionalInfo";
import updateBankInfo from "./profile/professionalInfo/updateBankInfo";
import updateTvaInfo from "./profile/professionalInfo/updateTvaInfo";
import updateLogo from "./profile/professionalInfo/updateLogo";
import fetchProject from "./reporting/project/fetchProjects";
import addProject from "./reporting/project/addProject";
import updateProject from "./reporting/project/updateProject";
import deleteProject from "./reporting/project/deleteProject";
import fetchBills from "./bill/fetchBills";
import addBill from "./bill/addBill";
import updateBill from "./bill/updateBill";
import deleteBill from "./bill/deleteBill";
import fetchStatistics from "./transaction/fetchStatistics";
import fetchTransactions from "./transaction/fetchTransactions";
import addTransaction from "./transaction/addTransaction";
import updateTransaction from "./transaction/updateTransaction";
import deleteTransaction from "./transaction/deleteTransaction";
import fetchEvents from "./planning/fetchEvents";
import dismissEvents from "./planning/dismissEvents";
import addEvent from "./planning/addEvent";
import updateEvent from "./planning/updateEvent";
import deleteEvent from "./planning/deleteEvent";

const rootEpic = combineEpics(
  fetchUser,
  updateUser,
  updateUserPicture,
  fetchData,
  sendMail,
  signIn,
  signUp,
  updateParams,
  fetchOptionSet,
  addOptionSet,
  updateOptionSet,
  deleteOptionSet,
  fetchContacts,
  addContact,
  updateContact,
  deleteContact,
  fetchOffers,
  addOffer,
  updateOffer,
  deleteOffer,
  fetchProfile,
  addProfile,
  updateProfile,
  deleteProfile,
  fetchCompanies,
  addCompany,
  updateCompany,
  deleteCompany,
  fetchCompanySuggestions,
  fetchCertifications,
  addCertification,
  updateCertification,
  deleteCertification,
  fetchMissions,
  addMission,
  updateMission,
  deleteMission,
  fetchInterests,
  addInterest,
  updateInterest,
  deleteInterest,
  fetchLanguages,
  addLanguage,
  updateLanguage,
  deleteLanguage,
  fetchSkills,
  addSkill,
  updateSkill,
  deleteSkill,
  fetchTrainings,
  addTraining,
  updateTraining,
  deleteTraining,
  fetchStudies,
  addStudy,
  updateStudy,
  deleteStudy,
  fetchProfessionalInfo,
  addProfessionalInfo,
  updateProfessionalInfo,
  deleteProfessionalInfo,
  updateBankInfo,
  updateTvaInfo,
  updateLogo,
  fetchProject,
  addProject,
  updateProject,
  deleteProject,
  fetchBills,
  addBill,
  updateBill,
  deleteBill,
  fetchStatistics,
  fetchTransactions,
  addTransaction,
  updateTransaction,
  deleteTransaction,
  fetchEvents,
  dismissEvents,
  addEvent,
  updateEvent,
  deleteEvent
);

export default rootEpic;
