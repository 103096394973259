import {
  FETCH_INTEREST,
  SET_INTEREST,
  ADD_INTEREST,
  UPDATE_INTEREST,
  DELETE_INTEREST,
} from "../../../constants/actions-types";

export const fetchInterests = () => ({
  type: FETCH_INTEREST,
  payload: {},
});

export const setInterests = (data) => ({
  type: SET_INTEREST,
  payload: data,
});

export const addInterest = (data) => ({
  type: ADD_INTEREST,
  payload: data,
});

export const updateInterest = (data) => ({
  type: UPDATE_INTEREST,
  payload: data,
});

export const deleteInterest = (index) => ({
  type: DELETE_INTEREST,
  payload: index,
});
