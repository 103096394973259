import { DELETE_CONTACT } from "../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setContacts } from "../../actions/contact";
import { remove } from "../../../repositories/contact";
import { setNotification } from "../../actions/common";

const deleteContact = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_CONTACT),
    switchMap(({ payload }) =>
      from(remove(state$.value.dataSet.contacts[payload].id)).pipe(
        flatMap(() =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully deleted"),
            }),
            setContacts(
              removeContact([...state$.value.dataSet.contacts], payload)
            )
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const removeContact = (contacts, index) => {
  contacts.splice(index, 1);
  return contacts;
};

export default deleteContact;
