import * as subjects from "../../../constants/subjects";
import * as permissions from "../constants/permissions";
import { store } from "../../../store/index.js";

export const defineRulesForOptionSet = (can, roles) => {
  if ([permissions.ALL].some((role) => roles.includes(role))) {
    can(permissions.MANAGE, subjects.OPTIONSET);
  } else {
    roles.forEach((perm) => {
      can(perm, subjects.OPTIONSET);
    });
  }
};

export const canAddOptionSet = () =>
  store
    .getState()
    .authentication.user.ability.can(
      permissions.OPTIONSET_ADD,
      subjects.OPTIONSET
    );

export const canUpdateOptionSet = () =>
  store
    .getState()
    .authentication.user.ability.can(
      permissions.OPTIONSET_UPDATE,
      subjects.OPTIONSET
    );

export const canDeleteOptionSet = () =>
  store
    .getState()
    .authentication.user.ability.can(
      permissions.OPTIONSET_DELETE,
      subjects.OPTIONSET
    );
