import { Get, Post, Put, Delete } from "../../utils/base-api-request";
import { store } from "../../store/index";
import { getTime } from "../../utils/date";
import { templateDtoToTemplateView } from "../mailing";

const getProjectsUrl = process.env.REACT_APP_BASE_API + "api/project/all";
const getProjectUrl = process.env.REACT_APP_BASE_API + "api/project";
const removeProjectUrl = process.env.REACT_APP_BASE_API + "api/project";
const addProjectUrl = process.env.REACT_APP_BASE_API + "api/project";
const updateProjectUrl = process.env.REACT_APP_BASE_API + "api/project";

export const getAll = async () => {
  const data = await Get(
    getProjectsUrl + "/" + store.getState().authentication.user.id
  );

  return data.map((d) => projectDtoToProjectView(d));
};

export const get = async (id) => {
  const data = await Get(getProjectUrl + "/" + id);
  return projectDtoToProjectView(data);
};

export const add = async (project) => {
  if (project["userId"] === undefined)
    project["userId"] = store.getState().authentication.user.id;

  const data = await Post(addProjectUrl, projectViewToProjectDto(project));
  return projectDtoToProjectView(data);
};

export const update = async (project) => {
  if (project["userId"] === undefined)
    project["userId"] = store.getState().authentication.user.id;

  const data = await Put(updateProjectUrl, projectViewToProjectDto(project));
  return projectDtoToProjectView(data);
};

export const remove = async (id) => {
  return await Delete(removeProjectUrl + "/" + id);
};

export const lookupData = (projects) => {
  let lookup = {};
  projects.forEach((project) => (lookup[project.id] = project.ref));

  return lookup;
};

const projectDtoToProjectView = (item) => {
  return {
    ...item,
    isRenewable: item.renewalPeriod && item.renewalPeriod > 0,
    templates: item.templates && item.templates.length > 0 ? item.templates.map(t => templateDtoToTemplateView(t)) : []
  };
};

const projectViewToProjectDto = (item) => {
  if (!item.isBillable) {
    delete item.clientId;
    delete item.priceHT;
    delete item.currency;
    delete item.isRenewable;
    delete item.startDate;
    delete item.endDate;
    delete item.renewalPeriod;
    delete item.paymentDeadline;
    delete item.penaltyRate;
    delete item.tva;
    delete item.client;
  }
  if (!item.isRenewable) delete item.renewalPeriod;
  return {
    ...item,
    startDate: item.startDate ? getTime(item.startDate) : item.startDate,
    endDate: item.endDate ? getTime(item.endDate) : item.endDate,
  };
};
