import { DELETE_BILL } from "../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setBills } from "../../actions/bill";
import { remove } from "../../../repositories/billing";
import { setNotification } from "../../actions/common";

const deleteBill = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_BILL),
    switchMap(({ payload }) =>
      from(remove(state$.value.dataSet.bills[payload].id)).pipe(
        flatMap(() =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully deleted"),
            }),
            setBills(removeBill([...state$.value.dataSet.bills], payload))
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const removeBill = (bills, index) => {
  bills.splice(index, 1);
  return bills;
};

export default deleteBill;
