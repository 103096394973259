import * as subjects from "../../../constants/subjects.js";
import * as permissions from "../constants/permissions.js";

export const defineRulesForTaskBoard = (can, roles) => {
  if ([permissions.ALL].some((role) => roles.includes(role))) {
    can(permissions.MANAGE, subjects.TASK_BOARD);
  } else {
    roles.forEach((perm) => {
      can(perm, subjects.TASK_BOARD);
    });
  }
};
