import axios from "axios";
import { store } from "../store/index.js";
import { signOut } from "../store/actions/authentication";
import keycloak from '../Keycloak.js';
import { tokenless } from "./base-api-request.js";

const customAxios = axios.create({
  timeout: 1000000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const onRequest = async (request) => {
  if (request.headers[tokenless]) {
    delete request.headers[tokenless]
    return request;
  }
  if (keycloak && keycloak.authenticated) {
    request.headers.Authorization = `Bearer ${keycloak.token}`;
    return request;
  }
  else {
    return keycloak.login();
  }
};

const onRequestError = (error) => {
  return Promise.reject(error);
};

const onResponse = (response) => {
  return response;
};

const onResponseError = async (error) => {
  if (error?.response?.status === 401) {
    store.dispatch(signOut());
  }
  return Promise.reject(error);
};

customAxios.interceptors.request.use(
  (request) => onRequest(request),
  (error) => onRequestError(error)
);
customAxios.interceptors.response.use(
  (response) => onResponse(response),
  (error) => onResponseError(error)
);

export default customAxios;
