import { FETCH_BILL } from "../../constants/actions-types";
import { mergeMap, flatMap, catchError } from "rxjs/operators";
import { of, from, merge } from "rxjs";
import { ofType } from "redux-observable";

import { setBills } from "../../actions/bill";
import { getAll } from "../../../repositories/billing";
import { setNotification } from "../../actions/common";

const fetchBills = (action$) =>
  action$.pipe(
    ofType(FETCH_BILL),
    mergeMap(() =>
      merge(
        from(getAll()).pipe(
          flatMap((resp) => of(setBills(resp))),
          catchError((error) =>
            of(
              setNotification({
                isSuccess: false,
                message: "Error : " + error,
              })
            )
          )
        )
      )
    )
  );

export default fetchBills;
