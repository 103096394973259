import * as subjects from "../../../constants/subjects";
import * as permissions from "../constants/permissions";
import { store } from "../../../store/index.js";

export const defineRulesForContacts = (can, roles) => {
  if ([permissions.ALL].some((role) => roles.includes(role))) {
    can(permissions.MANAGE, subjects.CONTACTS);
  } else {
    roles.forEach((perm) => {
      can(perm, subjects.CONTACTS);
    });
  }
};

export const canAddContact = () =>
  store
    .getState()
    .authentication.user.ability.can(
      permissions.CONTACT_ADD,
      subjects.CONTACTS
    );

export const canUpdateContact = () =>
  store
    .getState()
    .authentication.user.ability.can(
      permissions.CONTACT_UPDATE,
      subjects.CONTACTS
    );

export const canDeleteContact = () =>
  store
    .getState()
    .authentication.user.ability.can(
      permissions.CONTACT_DELETE,
      subjects.CONTACTS
    );
