import * as subjects from "../../../constants/subjects";
import * as permissions from "../constants/permissions";
import { store } from "../../../store/index.js";

export const defineRulesForProfessionalInfo = (can, roles) => {
  if ([permissions.ALL].some((role) => roles.includes(role))) {
    can(permissions.MANAGE, subjects.PROFESSIONALINFO);
  } else {
    roles.forEach((perm) => {
      can(perm, subjects.PROFESSIONALINFO);
    });
  }
};

export const canUpdateProfessionalInfo = () =>
  store
    .getState()
    .authentication.user.ability.can(
      permissions.PROFESSIONAL_INFO_UPDATE,
      subjects.PROFESSIONALINFO
    );
