import { Get, Post, Put, Delete } from "../../utils/base-api-request";
import { store } from "../../store/index";

const getSkillsUrl = process.env.REACT_APP_BASE_API + "api/skill/all";
const getSkillUrl = process.env.REACT_APP_BASE_API + "api/skill";
const removeSkillUrl = process.env.REACT_APP_BASE_API + "api/skill";
const addSkillUrl = process.env.REACT_APP_BASE_API + "api/skill";
const updateSkillUrl = process.env.REACT_APP_BASE_API + "api/skill";
const getSuggestionsUrl =
  process.env.REACT_APP_BASE_API + "api/skill/suggestions";

export const getAll = async () => {
  return await Get(
    getSkillsUrl + "/" + store.getState().authentication.user.id
  );
};

export const get = async (id) => {
  return await Get(getSkillUrl + "/" + id);
};

export const getSuggesstions = async () => {
  return await Get(
    getSuggestionsUrl + "/" + store.getState().authentication.user.id
  );
};

export const add = async (skill) => {
  if (skill["userId"] === undefined)
    skill["userId"] = store.getState().authentication.user.id;

  return await Post(addSkillUrl, skill);
};

export const update = async (skill) => {
  if (skill["userId"] === undefined)
    skill["userId"] = store.getState().authentication.user.id;

  return await Put(updateSkillUrl, skill);
};

export const remove = async (id) => {
  return await Delete(removeSkillUrl + "/" + id);
};

export const lookupData = (skills) => {
  let lookup = {};
  skills.forEach((skill) => (lookup[skill.id] = skill.name));

  return lookup;
};
