import { FETCH_PROJECT } from "../../../constants/actions-types";
import { mergeMap, flatMap, catchError, finalize } from "rxjs/operators";
import { of, from, merge } from "rxjs";
import { ofType } from "redux-observable";

import { setProjects } from "../../../actions/reporting/project";
import { getAll } from "../../../../repositories/project";
import { setNotification, setLoading } from "../../../actions/common";
import { dispatch } from "../../../../store";

const fetchProjects = (action$) =>
  action$.pipe(
    ofType(FETCH_PROJECT),
    mergeMap(() =>
      merge(
        of(setLoading(true)),
        from(getAll()).pipe(
          flatMap((resp) => of(setProjects(resp))),
          catchError((error) =>
            of(
              setNotification({
                isSuccess: false,
                message: "Error : " + error,
              })
            )
          ),
          finalize(() => dispatch(setLoading(false)))
        )
      )
    )
  );

export default fetchProjects;
