import { FETCH_OPTIONSET } from "../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";

import { setOptionSet } from "../../actions/optionSet";
import { getAll } from "../../../repositories/optionSet";
import { setNotification } from "../../actions/common";

const fetchOptionSet = (action$) =>
  action$.pipe(
    ofType(FETCH_OPTIONSET),
    switchMap(() =>
      from(getAll()).pipe(
        flatMap((resp) => of(setOptionSet(resp))),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

export default fetchOptionSet;
