import {
  FETCH_CONTACT,
  SET_CONTACT,
  ADD_CONTACT,
  UPDATE_CONTACT,
  DELETE_CONTACT,
} from "../../constants/actions-types";

export const fetchContacts = () => ({
  type: FETCH_CONTACT,
  payload: {},
});

export const setContacts = (data) => ({
  type: SET_CONTACT,
  payload: data,
});

export const addContact = (data) => ({
  type: ADD_CONTACT,
  payload: data,
});

export const updateContact = (data) => ({
  type: UPDATE_CONTACT,
  payload: data,
});
export const deleteContact = (index) => ({
  type: DELETE_CONTACT,
  payload: index,
});
