import {
  FETCH_BILL,
  SET_BILL,
  ADD_BILL,
  UPDATE_BILL,
  DELETE_BILL,
} from "../../constants/actions-types";

export const fetchBills = () => ({
  type: FETCH_BILL,
  payload: {},
});

export const setBills = (data) => ({
  type: SET_BILL,
  payload: data,
});

export const addBill = (data) => ({
  type: ADD_BILL,
  payload: data,
});

export const updateBill = (data) => ({
  type: UPDATE_BILL,
  payload: data,
});

export const deleteBill = (index) => ({
  type: DELETE_BILL,
  payload: index,
});
