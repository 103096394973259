import { Get, Post, Put, Delete } from "../../utils/base-api-request";
import { store } from "../../store/index";

const getInterestsUrl = process.env.REACT_APP_BASE_API + "api/interest/all";
const getInterestUrl = process.env.REACT_APP_BASE_API + "api/interest";
const removeInterestUrl = process.env.REACT_APP_BASE_API + "api/interest";
const addInterestUrl = process.env.REACT_APP_BASE_API + "api/interest";
const updateInterestUrl = process.env.REACT_APP_BASE_API + "api/interest";

export const sortData = (interests) => {
  return interests.sort((a, b) => (a.name > b.name ? 1 : -1));
};

export const getAll = async () => {
  const response = await Get(
    getInterestsUrl + "/" + store.getState().authentication.user.id
  );
  return sortData(response);
};

export const get = async (id) => {
  return await Get(getInterestUrl + "/" + id);
};

export const add = async (interest) => {
  if (interest["userId"] === undefined)
    interest["userId"] = store.getState().authentication.user.id;

  return await Post(addInterestUrl, interest);
};

export const update = async (interest) => {
  if (interest["userId"] === undefined)
    interest["userId"] = store.getState().authentication.user.id;

  return await Put(updateInterestUrl, interest);
};

export const remove = async (id) => {
  return await Delete(removeInterestUrl + "/" + id);
};

export const lookupData = (interests) => {
  let lookup = {};
  interests.forEach((interest) => (lookup[interest.id] = interest.name));

  return lookup;
};
