import {
  FETCH_TRAINING,
  SET_TRAINING,
  ADD_TRAINING,
  UPDATE_TRAINING,
  DELETE_TRAINING,
} from "../../../constants/actions-types";

export const fetchTrainings = () => ({
  type: FETCH_TRAINING,
  payload: {},
});

export const setTrainings = (data) => ({
  type: SET_TRAINING,
  payload: data,
});

export const addTraining = (data) => ({
  type: ADD_TRAINING,
  payload: data,
});

export const updateTraining = (data) => ({
  type: UPDATE_TRAINING,
  payload: data,
});

export const deleteTraining = (index) => ({
  type: DELETE_TRAINING,
  payload: index,
});
