import { UPDATE_LANGUAGE } from "../../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setLanguages } from "../../../actions/cv/language";
import { update, sortData } from "../../../../repositories/cv/language";
import { setNotification } from "../../../actions/common";

const updateLanguage = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_LANGUAGE),
    switchMap(({ payload }) =>
      from(update(payload.item)).pipe(
        flatMap((resp) =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully update"),
            }),
            setLanguages(
              updateLanguages(
                [...state$.value.dataSet.cv.languages],
                payload.index,
                resp
              )
            )
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const updateLanguages = (languages, index, item) => {
  languages[index] = item;
  return sortData(languages);
};

export default updateLanguage;
