import { UPDATE_CONTACT } from "../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setContacts } from "../../actions/contact";
import { update } from "../../../repositories/contact";
import { setNotification } from "../../actions/common";

const updateContact = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_CONTACT),
    switchMap(({ payload }) =>
      from(update(payload.item)).pipe(
        flatMap((resp) =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully update"),
            }),
            setContacts(
              updateContacts(
                [...state$.value.dataSet.contacts],
                payload.index,
                resp
              )
            )
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const updateContacts = (contacts, index, item) => {
  contacts[index] = item;
  return contacts;
};

export default updateContact;
