import { DELETE_COMPANY } from "../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setCompanies } from "../../actions/company";
import { remove } from "../../../repositories/company";
import { setNotification } from "../../actions/common";

const deleteCompany = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_COMPANY),
    switchMap(({ payload }) =>
      from(remove(state$.value.dataSet.companies[payload].id)).pipe(
        flatMap(() =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully deleted"),
            }),
            setCompanies(
              removeCompany([...state$.value.dataSet.companies], payload)
            )
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const removeCompany = (companies, index) => {
  companies.splice(index, 1);
  return companies;
};

export default deleteCompany;
