import { FETCH_TRANSACTION } from "../../constants/actions-types";
import { mergeMap, flatMap, catchError, finalize } from "rxjs/operators";
import { of, from, merge } from "rxjs";
import { ofType } from "redux-observable";

import { setIncomes, setOutcomes } from "../../actions/transaction";
import { getUnpaid } from "../../../repositories/portfolio/transaction";
import { setNotification, setLoading } from "../../actions/common";
import { dispatch } from "../..";
import { In, out } from "../../../utils/constants";

const fetchTransactions = (action$) =>
  action$.pipe(
    ofType(FETCH_TRANSACTION),
    mergeMap(() =>
      merge(
        of(setLoading(true)),
        from(getUnpaid()).pipe(
          flatMap((transactions) => of(setIncomes(transactions.filter(t => t.operation === In)), setOutcomes(transactions.filter(t => t.operation === out)))),
          catchError((error) =>
            of(
              setNotification({
                isSuccess: false,
                message: "Error : " + error,
              })
            )
          ),
          finalize(() => dispatch(setLoading(false)))
        )
      )
    )
  );

export default fetchTransactions;
