import {
  FETCH_LANGUAGE,
  SET_LANGUAGE,
  ADD_LANGUAGE,
  UPDATE_LANGUAGE,
  DELETE_LANGUAGE,
} from "../../../constants/actions-types";

export const fetchLanguages = () => ({
  type: FETCH_LANGUAGE,
  payload: {},
});

export const setLanguages = (data) => ({
  type: SET_LANGUAGE,
  payload: data,
});

export const addLanguage = (data) => ({
  type: ADD_LANGUAGE,
  payload: data,
});

export const updateLanguage = (data) => ({
  type: UPDATE_LANGUAGE,
  payload: data,
});

export const deleteLanguage = (index) => ({
  type: DELETE_LANGUAGE,
  payload: index,
});
