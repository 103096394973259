import { FETCH_TRAINING } from "../../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";

import { setTrainings } from "../../../actions/cv/training";
import { getAll } from "../../../../repositories/cv/training";
import { setNotification } from "../../../actions/common";

const fetchTrainings = (action$) =>
  action$.pipe(
    ofType(FETCH_TRAINING),
    switchMap(() =>
      from(getAll()).pipe(
        flatMap((resp) => of(setTrainings(resp))),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

export default fetchTrainings;
