import {
  FETCH_PROJECT,
  SET_PROJECT,
  ADD_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
} from "../../../constants/actions-types";

export const fetchProjects = () => ({
  type: FETCH_PROJECT,
  payload: {},
});

export const setProjects = (data) => ({
  type: SET_PROJECT,
  payload: data,
});

export const addProject = (data) => ({
  type: ADD_PROJECT,
  payload: data,
});

export const updateProject = (data) => ({
  type: UPDATE_PROJECT,
  payload: data,
});
export const deleteProject = (index) => ({
  type: DELETE_PROJECT,
  payload: index,
});
