export const pathsToObjects = (paths) => {
  const struct = [];
  convert(struct, paths[0].split("/"), 0);
  paths.forEach((path) => {
    convert(struct, path.split("/"), 0);
  });

  return struct;
};

const convert = (struct, tab, index) => {
  if (index < tab.length) {
    const result = struct.find((item) => item.key === tab[index]);
    if (!result) {
      const child = {
        key: tab[index],
        childrens: [],
      };
      struct.push(child);
      convert(child.childrens, tab, index + 1);
    } else convert(result.childrens ?? [], tab, index + 1);
  }

  return;
};

export const toUniqueObject = (inputArray, outputArray, key = "key") => {
  if (inputArray.length === 0 || outputArray.length === 0)
    return inputArray.length === 0 ? [...outputArray] : [...inputArray];

  const newArray = [...inputArray];
  outputArray.forEach((item) => {
    const result = newArray.find((i) => i[key] === item[key]);
    if (result)
      result.childrens = toUniqueObject(result.childrens, item.childrens);
    else newArray.push(item);
  });

  return newArray;
};

export const deepMergeObjectProperties = (array, output, key = "key") => {
  if (!array || array.length === 0) return;

  array.forEach((item) => {
    const obj = output.find((r) => r[key] === item[key]);
    if (obj) {
      if (item.childrens && item.childrens.length > 0) {
        if (!obj.childrens) obj.childrens = [];
        deepMergeObjectProperties(item.childrens, obj.childrens, key);
      }
    } else output.push({ ...item });
  });
};

export const getUserNameFromEmail = (email) => {
  let parts = email.split("@");
  if (parts.length > 0) return parts[0].replace(".external", "");

  return email.replace(".external", "");
};

export const searchBy = (array, criteria) => {
  return array.filter((obj) => {
    return Object.keys(obj).some((key) => {
      return obj[key] ? obj[key].toString().includes(criteria) : false;
    });
  });
};

export const filterBymulti = (array, filters) => {
  if (!filters || filters.length === 0) return array;

  let result = [...array];
  filters.forEach((filter) => {
    if (filter.column.customFilterAndSearch) {
      result = result.filter((obj) =>
        obj[filter.column.field]
          ? filter.column.customFilterAndSearch(filter.value, obj)
          : false
      );
    } else if (Array.isArray(filter.value)) {
      if (filter.value.length > 0)
        result = result.filter((obj) =>
          obj[filter.column.field]
            ? filter.value.some(
              (f) =>
                obj[filter.column.field].toString().toLowerCase() ===
                f.toString().toLowerCase()
            )
            : false
        );
    } else
      result = result.filter((obj) =>
        obj[filter.column.field]
          ? obj[filter.column.field]
            .toString()
            .toLowerCase()
            .includes(filter.value.toLowerCase())
          : false
      );
  });

  return result;
};

export const commonKeys = (obj1, obj2) => {
  var keys = {};
  for (var i in obj1) {
    if (i in obj2) {
      keys[i] = obj1[i];
    }
  }
  return keys;
};

export const sortByPropertyDesc = (array, property) =>
  array.sort((a, b) => (a[property] > b[property] ? 1 : -1));

export const sortByPropertyAsc = (array, property) =>
  array.sort((a, b) => (a[property] < b[property] ? 1 : -1));

export const sortByDesc = (array) => array.sort((a, b) => (a > b ? 1 : -1));

export const sortByAsc = (array) => array.sort((a, b) => (a < b ? 1 : -1));

export const decodeJwtResponse = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}


export const getLabelByValue = (array, value) => {
  const foundObject = array.find(obj => obj.value === value);
  if (foundObject) {
    return foundObject.label;
  }

  for (const obj of array.filter(obj => obj.children && obj.children.length > 0)) {
    const label = getLabelByValue(obj.children, value);
    if (label) {
      return label;
    }
  }

  return null;
};