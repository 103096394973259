import {
  FETCH_OFFER,
  SET_OFFER,
  ADD_OFFER,
  UPDATE_OFFER,
  DELETE_OFFER,
} from "../../constants/actions-types";

export const fetchOffers = () => ({
  type: FETCH_OFFER,
  payload: {},
});

export const setOffers = (data) => ({
  type: SET_OFFER,
  payload: data,
});

export const addOffer = (data) => ({
  type: ADD_OFFER,
  payload: data,
});

export const updateOffer = (data) => ({
  type: UPDATE_OFFER,
  payload: data,
});
export const deleteOffer = (index) => ({
  type: DELETE_OFFER,
  payload: index,
});
