import {
  FETCH_PROFESSIONALINFO,
  SET_PROFESSIONALINFO,
  ADD_PROFESSIONALINFO,
  UPDATE_PROFESSIONALINFO,
  DELETE_PROFESSIONALINFO,
  UPDATE_TVAINFO,
  UPDATE_BANKINFO,
  UPDATE_LOGO,
  SET_PROFESSIONALINFO_LOGO
} from "../../../constants/actions-types";

export const fetchProfessionalInfo = () => ({
  type: FETCH_PROFESSIONALINFO,
  payload: {},
});

export const updateLogo = (data) => ({
  type: UPDATE_LOGO,
  payload: data,
});

export const setLogo = (data) => ({
  type: SET_PROFESSIONALINFO_LOGO,
  payload: data,
});

export const setProfessionalInfo = (data) => ({
  type: SET_PROFESSIONALINFO,
  payload: data,
});

export const addProfessionalInfo = (data) => ({
  type: ADD_PROFESSIONALINFO,
  payload: data,
});

export const updateProfessionalInfo = (data) => ({
  type: UPDATE_PROFESSIONALINFO,
  payload: data,
});

export const updateTvaInfo = (data) => ({
  type: UPDATE_TVAINFO,
  payload: data,
});

export const updateBankInfo = (data) => ({
  type: UPDATE_BANKINFO,
  payload: data,
});

export const deleteProfessionalInfo = (index) => ({
  type: DELETE_PROFESSIONALINFO,
  payload: index,
});
