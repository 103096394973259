import { FETCH_STATISTICS } from "../../constants/actions-types";
import { mergeMap, flatMap, catchError } from "rxjs/operators";
import { of, from, merge } from "rxjs";
import { ofType } from "redux-observable";

import { setStatistics } from "../../actions/transaction";
import { getStatistics } from "../../../repositories/portfolio/transaction";
import { setNotification } from "../../actions/common";

const fetchStatistics = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_STATISTICS),
    mergeMap(({ payload }) =>
      merge(
        from(getStatistics(payload)).pipe(
          flatMap((resp) => of(setStatistics(manageStatistics({ ...state$.value.dataSet.statistics }, resp)))),
          catchError((error) =>
            of(
              setNotification({
                isSuccess: false,
                message: "Error : " + error,
              })
            )
          )
        )
      )
    )
  );

const manageStatistics = (data, resp) => {
  resp.forEach(item => {
    data[item.year] = item;
  });
  return data
}

export default fetchStatistics;
