import React from "react";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./reducers";
import rootEpic from "./epics";
import { createEpicMiddleware } from "redux-observable";
import { composeWithDevTools } from "redux-devtools-extension";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

const epicMiddleware = createEpicMiddleware();

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["notification"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(epicMiddleware))
);
const persistor = persistStore(store);
epicMiddleware.run(rootEpic);

export const ReduxProvider = ({ children }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor} children={children} />
  </Provider>
);
export const dispatch = store.dispatch;
