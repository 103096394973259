import { UPDATE_INTEREST } from "../../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setInterests } from "../../../actions/cv/interest";
import { update, sortData } from "../../../../repositories/cv/interest";
import { setNotification } from "../../../actions/common";

const updateInterest = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_INTEREST),
    switchMap(({ payload }) =>
      from(update(payload.item)).pipe(
        flatMap((resp) =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully update"),
            }),
            setInterests(
              updateInterests(
                [...state$.value.dataSet.cv.interests],
                payload.index,
                resp
              )
            )
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const updateInterests = (interests, index, item) => {
  interests[index] = item;
  return sortData(interests);
};

export default updateInterest;
