import { DELETE_INTEREST } from "../../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setInterests } from "../../../actions/cv/interest";
import { remove } from "../../../../repositories/cv/interest";
import { setNotification } from "../../../actions/common";

const deleteInterest = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_INTEREST),
    switchMap(({ payload }) =>
      from(remove(payload)).pipe(
        flatMap(() =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully deleted"),
            }),
            setInterests(
              removeInterest([...state$.value.dataSet.cv.interests], payload)
            )
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const removeInterest = (interests, id) => {
  return interests.filter((interest) => interest.id !== id);
};

export default deleteInterest;
