import { DELETE_PROJECT } from "../../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setProjects } from "../../../actions/reporting/project";
import { remove } from "../../../../repositories/project";
import { setNotification } from "../../../actions/common";

const deleteProject = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_PROJECT),
    switchMap(({ payload }) =>
      from(remove(state$.value.dataSet.reporting.projects[payload].id)).pipe(
        flatMap(() =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully deleted"),
            }),
            setProjects(
              removeProject(
                [...state$.value.dataSet.reporting.projects],
                payload
              )
            )
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const removeProject = (projects, index) => {
  projects.splice(index, 1);
  return projects;
};

export default deleteProject;
