import { ADD_TRANSACTION } from "../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import { setTransactions } from "../../actions/transaction";
import { add, sortData } from "../../../repositories/portfolio/transaction";
import { setNotification } from "../../actions/common";

const addTransaction = (action$, state$) =>
  action$.pipe(
    ofType(ADD_TRANSACTION),
    switchMap(({ payload }) =>
      from(add(payload)).pipe(
        flatMap((resp) =>
          of(
            setTransactions(addTransactions([...state$.value.dataSet.transactions], resp))
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const addTransactions = (transactions, item) => {
  transactions.push(item);
  return sortData(transactions);
};

export default addTransaction;
