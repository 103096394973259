import { Get, Post, Put, Delete } from "../../utils/base-api-request";
import { store } from "../../store/index";

const getTrainingsUrl = process.env.REACT_APP_BASE_API + "api/training/all";
const getTrainingUrl = process.env.REACT_APP_BASE_API + "api/training";
const removeTrainingUrl = process.env.REACT_APP_BASE_API + "api/training";
const addTrainingUrl = process.env.REACT_APP_BASE_API + "api/training";
const updateTrainingUrl = process.env.REACT_APP_BASE_API + "api/training";

export const sortData = (trainings) => {
  return trainings.sort((a, b) => {
    if (a.year === b.year) {
      return a.month === b.month || a.month > b.month ? -1 : 1;
    } else {
      return a.year > b.year ? -1 : 1;
    }
  });
};

export const getAll = async () => {
  const response = await Get(
    getTrainingsUrl + "/" + store.getState().authentication.user.id
  );
  return sortData(response);
};

export const get = async (id) => {
  return await Get(getTrainingUrl + "/" + id);
};

export const add = async (training) => {
  if (training["userId"] === undefined)
    training["userId"] = store.getState().authentication.user.id;

  return await Post(addTrainingUrl, training);
};

export const update = async (training) => {
  if (training["userId"] === undefined)
    training["userId"] = store.getState().authentication.user.id;

  return await Put(updateTrainingUrl, training);
};

export const remove = async (id) => {
  return await Delete(removeTrainingUrl + "/" + id);
};

export const lookupData = (trainings) => {
  let lookup = {};
  trainings.forEach((training) => (lookup[training.id] = training.title));

  return lookup;
};
