import { ADD_TRAINING } from "../../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setTrainings } from "../../../actions/cv/training";
import { add, sortData } from "../../../../repositories/cv/training";
import { setNotification } from "../../../actions/common";

const addTraining = (action$, state$) =>
  action$.pipe(
    ofType(ADD_TRAINING),
    switchMap(({ payload }) =>
      from(add(payload)).pipe(
        flatMap((resp) =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully added"),
            }),
            setTrainings(
              addTrainings([...state$.value.dataSet.cv.trainings], resp)
            )
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const addTrainings = (trainings, item) => {
  trainings.push(item);
  return sortData(trainings);
};

export default addTraining;
