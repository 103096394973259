import { FETCH_OFFER } from "../../constants/actions-types";
import { mergeMap, flatMap, catchError, finalize } from "rxjs/operators";
import { of, from, merge } from "rxjs";
import { ofType } from "redux-observable";

import { setOffers } from "../../actions/offer";
import { getAll } from "../../../repositories/offer";
import { setNotification, setLoading } from "../../actions/common";
import { dispatch } from "../../../store";

const fetchOffers = (action$) =>
  action$.pipe(
    ofType(FETCH_OFFER),
    mergeMap(() =>
      merge(
        of(setLoading(true)),
        from(getAll()).pipe(
          flatMap((resp) => of(setOffers(resp))),
          catchError((error) =>
            of(
              setNotification({
                isSuccess: false,
                message: "Error : " + error,
              })
            )
          ),
          finalize(() => dispatch(setLoading(false)))
        )
      )
    )
  );

export default fetchOffers;
