import {
  FETCH_COMPANY_SUGGESTIONS,
  SET_COMPANY_SUGGESTIONS,
  FETCH_COMPANY,
  SET_COMPANY,
  ADD_COMPANY,
  UPDATE_COMPANY,
  DELETE_COMPANY,
} from "../../constants/actions-types";

export const fetchCompanies = () => ({
  type: FETCH_COMPANY,
  payload: {},
});

export const setCompanies = (data) => ({
  type: SET_COMPANY,
  payload: data,
});

export const fetchCompanySuggestions = () => ({
  type: FETCH_COMPANY_SUGGESTIONS,
  payload: {},
});

export const setCompanySuggestions = (data) => ({
  type: SET_COMPANY_SUGGESTIONS,
  payload: data,
});

export const addCompany = (data) => ({
  type: ADD_COMPANY,
  payload: data,
});

export const updateCompany = (data) => ({
  type: UPDATE_COMPANY,
  payload: data,
});

export const deleteCompany = (index) => ({
  type: DELETE_COMPANY,
  payload: index,
});
