import * as subjects from "../../../constants/subjects";
import * as permissions from "../constants/permissions";
import { store } from "../../../store/index.js";

export const defineRulesForCompanies = (can, roles) => {
  if ([permissions.ALL].some((role) => roles.includes(role))) {
    can(permissions.MANAGE, subjects.COMPANIES);
  } else {
    roles.forEach((perm) => {
      can(perm, subjects.COMPANIES);
    });
  }
};

export const canAddCompany = () =>
  store
    .getState()
    .authentication.user.ability.can(
      permissions.COMPANY_ADD,
      subjects.COMPANIES
    );

export const canUpdateCompany = () =>
  store
    .getState()
    .authentication.user.ability.can(
      permissions.COMPANY_UPDATE,
      subjects.COMPANIES
    );

export const canDeleteCompany = () =>
  store
    .getState()
    .authentication.user.ability.can(
      permissions.COMPANY_DELETE,
      subjects.COMPANIES
    );
