import { Get, Post, Put, Delete } from "../../utils/base-api-request";
import { store } from "../../store/index";

const getLanguagesUrl = process.env.REACT_APP_BASE_API + "api/language/all";
const getLanguageUrl = process.env.REACT_APP_BASE_API + "api/language";
const removeLanguageUrl = process.env.REACT_APP_BASE_API + "api/language";
const addLanguageUrl = process.env.REACT_APP_BASE_API + "api/language";
const updateLanguageUrl = process.env.REACT_APP_BASE_API + "api/language";

export const sortData = (languages) => {
  return languages.sort((a, b) => (a.name > b.name ? 1 : -1));
};

export const getAll = async () => {
  const response = await Get(
    getLanguagesUrl + "/" + store.getState().authentication.user.id
  );
  return sortData(response);
};

export const get = async (id) => {
  return await Get(getLanguageUrl + "/" + id);
};

export const add = async (language) => {
  if (language["userId"] === undefined)
    language["userId"] = store.getState().authentication.user.id;

  return await Post(addLanguageUrl, language);
};

export const update = async (language) => {
  if (language["userId"] === undefined)
    language["userId"] = store.getState().authentication.user.id;

  return await Put(updateLanguageUrl, language);
};

export const remove = async (id) => {
  return await Delete(removeLanguageUrl + "/" + id);
};

export const lookupData = (languages) => {
  let lookup = {};
  languages.forEach((language) => (lookup[language.id] = language.name));

  return lookup;
};
