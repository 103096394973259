import { ADD_BILL } from "../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setBills } from "../../actions/bill";
import { add, sortData } from "../../../repositories/billing";
import { setNotification } from "../../actions/common";

const addBill = (action$, state$) =>
  action$.pipe(
    ofType(ADD_BILL),
    switchMap(({ payload }) =>
      from(add(payload)).pipe(
        flatMap((resp) =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully added"),
            }),
            setBills(addBills([...state$.value.dataSet.bills], resp))
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const addBills = (bills, item) => {
  bills.push(item);
  return sortData(bills);
};

export default addBill;
