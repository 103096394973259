import { ADD_LANGUAGE } from "../../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setLanguages } from "../../../actions/cv/language";
import { add, sortData } from "../../../../repositories/cv/language";
import { setNotification } from "../../../actions/common";

const addLanguage = (action$, state$) =>
  action$.pipe(
    ofType(ADD_LANGUAGE),
    switchMap(({ payload }) =>
      from(add(payload)).pipe(
        flatMap((resp) =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully added"),
            }),
            setLanguages(
              addLanguages([...state$.value.dataSet.cv.languages], resp)
            )
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const addLanguages = (languages, item) => {
  languages.push(item);
  return sortData(languages);
};

export default addLanguage;
