import { fr } from "../../../utils/constants";
import {
  SET_USER_DATA,
  SIGNOUT,
  SET_SIGN_UP,
  SET_USER_ABILITY,
  UPDATE_PARAMS,
  SET_AUTH
} from "../../constants/actions-types";

const initialState = {
  isSignUp: false,
  isLoading: false,
  isError: false,
  params: { preferredLanguage: fr, isDiscreet: false, },
  authenticated: false,
  user: {
    data: {},
    permissions: [],
    ability: {},
  },
};

const handlers = {
  [SET_USER_DATA](state, payload) {
    return payload.isLogin ?
      {
        ...state,
        authenticated: true,
        params: { ...initialState.params, ...{ ...payload.data.params, ...{ preferredLanguage: payload.data.params.preferredLanguage ?? initialState.params.preferredLanguage } } },
        user: { id: payload.data.id, userName: payload.data.userName, token: payload.data.token, permissions: payload.data.permissions, ability: payload.data.ability, data: payload.data },
      } : {
        ...state,
        authenticated: true,
        user: {
          ...state.user,
          data: payload.data
        }
      };
  },
  [SET_AUTH](state, payload) {
    return {
      ...state,
      auth: payload
    };
  },
  [SET_USER_ABILITY](state, payload) {
    return {
      ...state,
      user: {
        ...state.user,
        ability: payload,
      },
    };
  },
  [UPDATE_PARAMS](state, payload) {
    return {
      ...state,
      params: payload,
    };
  },

  [SIGNOUT]() {
    return initialState;
  },
  [SET_SIGN_UP](state) {
    return {
      ...state,
      isSignUp: true,
    };
  },
};

const AuthenticationReducer = (state = initialState, { type, payload }) => {
  const actionHandler = handlers[type];
  if (actionHandler) {
    return actionHandler(state, payload);
  }
  return state;
};

export default AuthenticationReducer;
