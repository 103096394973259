import { Get, Post, Put, Delete } from "../../utils/base-api-request";
import { store } from "../../store/index";

const getSuggestionsUrl =
  process.env.REACT_APP_BASE_API + "api/company/suggestions";
const getCompaniesUrl = process.env.REACT_APP_BASE_API + "api/company/all";
const getCompanyUrl = process.env.REACT_APP_BASE_API + "api/company";
const removeCompanyUrl = process.env.REACT_APP_BASE_API + "api/company";
const addCompanyUrl = process.env.REACT_APP_BASE_API + "api/company";
const updateCompanyUrl = process.env.REACT_APP_BASE_API + "api/company";

export const getAll = async () => {
  return await Get(
    getCompaniesUrl + "/" + store.getState().authentication.user.id
  );
};

export const get = async (id) => {
  return await Get(getCompanyUrl + "/" + id);
};

export const getSuggestions = async () => {
  return await Get(
    getSuggestionsUrl + "/" + store.getState().authentication.user.id
  );
};

export const add = async (company) => {
  if (company["userId"] === undefined)
    company["userId"] = store.getState().authentication.user.id;

  return await Post(addCompanyUrl, company);
};

export const update = async (company) => {
  if (company["userId"] === undefined)
    company["userId"] = store.getState().authentication.user.id;

  return await Put(updateCompanyUrl, company);
};

export const remove = async (id) => {
  return await Delete(removeCompanyUrl + "/" + id);
};

export const lookupData = (companies) => {
  let lookup = {};
  companies.forEach((company) => (lookup[company.id] = company.name));

  return lookup;
};
