export const ALL = "ALL";
export const NAVIGATION = "Navigation";
export const DASHBOARD = "Dashboard";
export const COMPANIES = "Companies";
export const PROSPECTING = "Prospecting";
export const PROFILE = "Profile";
export const PERSONALINFO = "PersonalInfo";
export const PROFESSIONALINFO = "ProfessionalInfo";
export const CV = "CV";
export const CONTACTS = "Contacts";
export const OFFERS = "Offers";
export const MANAGEMENT = "Management";
export const PORTFOLIO = "Portfolio";
export const TRANSACTIONS = "Transactions";
export const CHARGES = "Charges";
export const CONTRACTS = "Contracts";
export const TIMESHEETS = "Timesheets";
export const BILLING = "Billing";
export const ADMINISTRATION = "Administration";
export const STATISTICS = "Statistics";
export const SETTINGS = "Settings";
export const OPTIONSET = "OptionSet";
export const ACCESSMANAGEMENT = "AccessManagement";
export const ROLES = "Roles";
export const USERS = "Users";
export const PLANNING = "Planning";
export const CALENDAR = "Calendar";
export const STATS_BILLS = "StatsBills";
export const EVENTS = "Events";

export const STOCKMANAGER = "StockManager";
export const ECOMMERCE = "Ecommerce";
export const CAMPAIGNS = "Campaigns";
export const PRODUCTS = "Products";
export const LANDING = "Landing";
export const NEWACCESS = "NewAccess";
export const TASK_BOARD = "TaskBoard";

