import {
  FETCH_TRANSACTION,
  SET_TRANSACTION,
  ADD_TRANSACTION,
  UPDATE_TRANSACTION,
  DELETE_TRANSACTION,
  FETCH_STATISTICS,
  SET_STATISTICS,
  SET_INCOMES,
  SET_OUTCOMES,
} from "../../constants/actions-types";

export const fetchStatistics = (data) => ({
  type: FETCH_STATISTICS,
  payload: data,
});
export const setStatistics = (data) => ({
  type: SET_STATISTICS,
  payload: data,
});
export const setIncomes = (data) => ({
  type: SET_INCOMES,
  payload: data,
});
export const setOutcomes = (data) => ({
  type: SET_OUTCOMES,
  payload: data,
});
export const fetchTransactions = () => ({
  type: FETCH_TRANSACTION,
  payload: {},
});

export const setTransactions = (data) => ({
  type: SET_TRANSACTION,
  payload: data,
});

export const addTransaction = (data) => ({
  type: ADD_TRANSACTION,
  payload: data,
});

export const updateTransaction = (data) => ({
  type: UPDATE_TRANSACTION,
  payload: data,
});
export const deleteTransaction = (index) => ({
  type: DELETE_TRANSACTION,
  payload: index,
});
