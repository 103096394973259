import { Get, Post, Put, Delete } from "../../utils/base-api-request";
import { store } from "../../store/index";

const getTemplatesByEntityUrl = process.env.REACT_APP_BASE_API + "api/mail/entity/{userId}/{entity}";
const removeTemplatesUrl = process.env.REACT_APP_BASE_API + "api/mail";
const addTemplatesUrl = process.env.REACT_APP_BASE_API + "api/mail";
const updateTemplatesUrl = process.env.REACT_APP_BASE_API + "api/mail";
const emailUrl = process.env.REACT_APP_BASE_API + "api/mail/send";

export const sortData = (array) => {
  return array.sort((a, b) => {
    if (a.id < b.id) return 1;
    if (a.id > b.id) return -1;

    return 0;
  });
};

export const sendUserMail = async (mail) => {
  return await Post(emailUrl, { mail: templateViewToTemplateDto(mail), attachments: mail.attachments && mail.attachments.length > 0 ? mail.attachments : [] });
};

export const getAllByEntity = async (entity) => {
  const templates = await Get(getTemplatesByEntityUrl.replace("{userId}", store.getState().authentication.user.id).replace("{entity}", entity));
  return sortData(templates.map((template) => templateDtoToTemplateView(template)));
};

export const add = async (template) => {
  if (template["userId"] === undefined)
    template["userId"] = store.getState().authentication.user.id;

  const response = await Post(addTemplatesUrl, templateViewToTemplateDto(template));
  return templateDtoToTemplateView(response);
};

export const update = async (template) => {
  if (template["userId"] === undefined)
    template["userId"] = store.getState().authentication.user.id;

  const response = await Put(updateTemplatesUrl, templateViewToTemplateDto(template));
  return templateDtoToTemplateView(response);
};

export const remove = async (id) => {
  return await Delete(removeTemplatesUrl + "/" + id);
};

export const lookupData = (bills) => {
  let lookup = {};
  bills.forEach((bill) => (lookup[bill.id] = bill.ref));

  return lookup;
};

export const templateDtoToTemplateView = (item) => {
  return {
    ...item,
    body: item.body.replaceAll('<p style="margin: 0">', '<p>')
  };
};

const templateViewToTemplateDto = (item) => {
  return {
    ...item,
    from: store.getState().authentication.user.userName,
    body: item.body.replaceAll('<p>', '<p style="margin: 0">')
  };
};
