import { UPDATE_PROFESSIONALINFO } from "../../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import { setProfessionalInfo } from "../../../actions/profile/professionalInfo";
import { update } from "../../../../repositories/profiles/professionalInfo";
import { setNotification } from "../../../actions/common";

const updateProfessionalInfo = (action$) =>
  action$.pipe(
    ofType(UPDATE_PROFESSIONALINFO),
    switchMap(({ payload }) =>
      from(update(payload)).pipe(
        flatMap((resp) =>
          of(
            // setNotification({
            //   isSuccess: true,
            //   message: i18next.t("successfully update"),
            // }),
            setProfessionalInfo(resp)
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

export default updateProfessionalInfo;
