import { UPDATE_COMPANY } from "../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setCompanies } from "../../actions/company";
import { update } from "../../../repositories/company";
import { setNotification } from "../../actions/common";

const updateCompany = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_COMPANY),
    switchMap(({ payload }) =>
      from(update(payload.item)).pipe(
        flatMap((resp) =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully update"),
            }),
            setCompanies(
              updateCompanies(
                [...state$.value.dataSet.companies],
                payload.index,
                resp
              )
            )
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const updateCompanies = (companies, index, item) => {
  companies[index] = item;
  return companies;
};

export default updateCompany;
