import { SIGN_IN } from "../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import { fetchUser, setAuth } from "../../actions/authentication";
import { setNotification } from "../../actions/common";
// import { initializeKeycloak } from "../../../repositories/accessManagement/Keycloak";

const signIn = (action$) =>
  action$.pipe(
    ofType(SIGN_IN),
    switchMap(() =>
      from().pipe(
        flatMap((data) =>
          of(
            setAuth(data),
            fetchUser()
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

export default signIn;
