import { UPDATE_OFFER } from "../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setOffers } from "../../actions/offer";
import { update } from "../../../repositories/offer";
import { setNotification } from "../../actions/common";

const updateOffer = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_OFFER),
    switchMap(({ payload }) =>
      from(update(payload.item)).pipe(
        flatMap((resp) =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully update"),
            }),
            setOffers(
              updateOffers(
                [...state$.value.dataSet.offers],
                payload.index,
                resp
              )
            )
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const updateOffers = (offers, index, item) => {
  offers[index] = item;
  return offers;
};

export default updateOffer;
