import React, { useEffect } from "react";
import NotificationAlert from "react-notification-alert";
import { useSelector } from "react-redux";

const Notification = () => {
  const lastCallDateTime = useSelector(
    (state) => state.notification.notification.lastCallDateTime
  );
  const isSuccess = useSelector(
    (state) => state.notification.notification.isSuccess
  );
  const message = useSelector(
    (state) => state.notification.notification.message
  );

  const notificationAlert = React.createRef();
  const icon = isSuccess ? "nc-icon nc-check-2" : "nc-icon nc-bell-55";
  useEffect(() => {
    if (message !== "") {
      const options = {
        place: "tr",
        message: (
          <div>
            <div>{message}</div>
          </div>
        ),
        type: isSuccess ? "success" : "danger",
        icon: icon,
        autoDismiss: isSuccess ? 5 : 20,
      };
      notificationAlert.current.notificationAlert(options);
    }
    // eslint-disable-next-line
  }, [lastCallDateTime]);

  return <NotificationAlert ref={notificationAlert} />;
};

export default Notification;
