import { ADD_COMPANY } from "../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setCompanies } from "../../actions/company";
import { add } from "../../../repositories/company";
import { setNotification } from "../../actions/common";

const addCompany = (action$, state$) =>
  action$.pipe(
    ofType(ADD_COMPANY),
    switchMap(({ payload }) =>
      from(add(payload)).pipe(
        flatMap((resp) =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully added"),
            }),
            setCompanies(
              addCompanies([...state$.value.dataSet.companies], resp)
            )
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const addCompanies = (companies, item) => {
  companies.push(item);
  return companies;
};

export default addCompany;
