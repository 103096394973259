import { FETCH_COMPANY_SUGGESTIONS } from "../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";

import { setCompanySuggestions } from "../../actions/company";
import { getSuggestions } from "../../../repositories/company";
import { setNotification } from "../../actions/common";

const fectchCompanySuggestions = (action$) =>
  action$.pipe(
    ofType(FETCH_COMPANY_SUGGESTIONS),
    switchMap(() =>
      from(getSuggestions()).pipe(
        flatMap((resp) => of(setCompanySuggestions(resp))),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

export default fectchCompanySuggestions;
