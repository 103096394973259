import {
  FETCH_STUDY,
  SET_STUDY,
  ADD_STUDY,
  UPDATE_STUDY,
  DELETE_STUDY,
} from "../../../constants/actions-types";

export const fetchStudies = () => ({
  type: FETCH_STUDY,
  payload: {},
});

export const setStudies = (data) => ({
  type: SET_STUDY,
  payload: data,
});

export const addStudy = (data) => ({
  type: ADD_STUDY,
  payload: data,
});

export const updateStudy = (data) => ({
  type: UPDATE_STUDY,
  payload: data,
});

export const deleteStudy = (index) => ({
  type: DELETE_STUDY,
  payload: index,
});
