import {
  FETCH_SKILL,
  SET_SKILL,
  ADD_SKILL,
  UPDATE_SKILL,
  DELETE_SKILL,
} from "../../../constants/actions-types";

export const fetchSkills = () => ({
  type: FETCH_SKILL,
  payload: {},
});

export const setSkills = (data) => ({
  type: SET_SKILL,
  payload: data,
});

export const addSkill = (data) => ({
  type: ADD_SKILL,
  payload: data,
});

export const updateSkill = (data) => ({
  type: UPDATE_SKILL,
  payload: data,
});

export const deleteSkill = (index) => ({
  type: DELETE_SKILL,
  payload: index,
});
