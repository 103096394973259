import { ADD_STUDY } from "../../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setStudies } from "../../../actions/cv/study";
import { add, sortData } from "../../../../repositories/cv/study";
import { setNotification } from "../../../actions/common";

const addStudy = (action$, state$) =>
  action$.pipe(
    ofType(ADD_STUDY),
    switchMap(({ payload }) =>
      from(add(payload)).pipe(
        flatMap((resp) =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully added"),
            }),
            setStudies(addStudies([...state$.value.dataSet.cv.studies], resp))
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const addStudies = (studies, item) => {
  studies.push(item);
  return sortData(studies);
};

export default addStudy;
