// import * as actionTypes from "../../actions/common/index";
import * as actionTypes from "../../constants/actions-types";
import config from "../../../config";

const initialState = {
  isOpen: [], //for active default menu
  isTrigger: [], //for active default menu, set blank for horizontal
  ...config,
  isFullScreen: false, // static can't change
  isLoading: false
};

let trigger = [];
let open = [];

const handlers = {
  [actionTypes.SIGNOUT]() {
    return initialState;
  },
  [actionTypes.COLLAPSE_MENU](state) {
    return {
      ...state,
      collapseMenu: !state.collapseMenu,
    };
  },
  [actionTypes.COLLAPSE_TOGGLE](state, menu) {
    if (menu.type === "sub") {
      open = state.isOpen;
      trigger = state.isTrigger;

      const triggerIndex = trigger.indexOf(menu.id);
      if (triggerIndex > -1) {
        open = open.filter((item) => item !== menu.id);
        trigger = trigger.filter((item) => item !== menu.id);
      }

      if (triggerIndex === -1) {
        open = [...open, menu.id];
        trigger = [...trigger, menu.id];
      }
    } else {
      open = state.isOpen;
      const triggerIndex = state.isTrigger.indexOf(menu.id);
      trigger = triggerIndex === -1 ? [menu.id] : [];
      open = triggerIndex === -1 ? [menu.id] : [];
    }

    return {
      ...state,
      isOpen: open,
      isTrigger: trigger,
    };
  },
  [actionTypes.NAV_CONTENT_LEAVE](state) {
    return {
      ...state,
      isOpen: open,
      isTrigger: trigger,
    };
  },
  [actionTypes.NAV_COLLAPSE_LEAVE](state, menu) {
    if (menu.type === "sub") {
      open = state.isOpen;
      trigger = state.isTrigger;

      const triggerIndex = trigger.indexOf(menu.id);
      if (triggerIndex > -1) {
        open = open.filter((item) => item !== menu.id);
        trigger = trigger.filter((item) => item !== menu.id);
      }
      return {
        ...state,
        isOpen: open,
        isTrigger: trigger,
      };
    }
    return { ...state };
  },
  [actionTypes.FULL_SCREEN](state) {
    return {
      ...state,
      isFullScreen: !state.isFullScreen,
    };
  },
  [actionTypes.FULL_SCREEN_EXIT](state) {
    return {
      ...state,
      isFullScreen: false,
    };
  },
  [actionTypes.CHANGE_LAYOUT](state, layout) {
    return {
      ...state,
      layout: layout,
    };
  },
  [actionTypes.SET_LOADING](state, payload) {
    return {
      ...state,
      isLoading: payload,
    };
  },
  [actionTypes.SET_URL](state, payload) {
    return {
      ...state,
      url: payload,
    };
  },
};

const CommonReducer = (state = initialState, { type, payload }) => {
  const actionHandler = handlers[type];
  if (actionHandler) {
    return actionHandler(state, payload);
  }
  return state;
};

export default CommonReducer;
