import {
  FETCH_PROFILE,
  SET_PROFILE,
  ADD_PROFILE,
  UPDATE_PROFILE,
  DELETE_PROFILE,
} from "../../../constants/actions-types";

export const fetchProfile = () => ({
  type: FETCH_PROFILE,
  payload: {},
});

export const setProfile = (data) => ({
  type: SET_PROFILE,
  payload: data,
});

export const addProfile = (data) => ({
  type: ADD_PROFILE,
  payload: data,
});

export const updateProfile = (data) => ({
  type: UPDATE_PROFILE,
  payload: data,
});

export const deleteProfile = (index) => ({
  type: DELETE_PROFILE,
  payload: index,
});
