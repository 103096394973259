const baseAdmin = "/admin";

const URLS = {
  BASENAME: "/",
  BLANK_LINK: "#",
  NEWER: baseAdmin + "/welcome",
  SOURCE: "/",
  ADMIN: baseAdmin,
  // SIGNUP: "/auth/signup",
  DASHBOARD_DEFAULT: baseAdmin + "/dashboard/default",
  DASHBOARD_STATS_BILLS: baseAdmin + "/dashboard/statistics/billing",
  PLANNING_CALENDAR: baseAdmin + "/planning/calendar",
  PROFILE_PERSONAL: baseAdmin + "/profile/personal",
  CV: baseAdmin + "/profile/cv",
  SETTINGS_OPTIONSET: baseAdmin + "/settings/optionSet",
  PROSPECTING_CONTACT: baseAdmin + "/prospecting/contact",
  PROSPECTING_COMPANY: baseAdmin + "/prospecting/company",
  PROSPECTING_OFFER: baseAdmin + "/prospecting/offer",
  CUSTOMER_COMPANY: baseAdmin + "/customer/company",
  MANAGEMENT_TIMESHEET: baseAdmin + "/management/timesheets",
  MANAGEMENT_CONTRACT: baseAdmin + "/management/contracts",
  MANAGEMENT_TASK_BOARD: baseAdmin + "/management/board",
  BILLING: baseAdmin + "/management/billing",
  PORTFOLIO_TRANSACTIONS: baseAdmin + "/portfolio/transactions",
  PORTFOLIO_CHARGES: baseAdmin + "/portfolio/charges",
  SETTINGS_ROLE: baseAdmin + "/access/roles",
  SETTINGS_USER: baseAdmin + "/access/users",
  ECOMMERCE_CAMPAIGNS: baseAdmin + "/ecommerce/campaigns",
  ECOMMERCE_PRODUCTS: baseAdmin + "/ecommerce/products",
};

// export const fullUrl = (url) => URLS.BASENAME + "/" + URL;

export default URLS;
