import { Get, Post, Put, Delete } from "../../utils/base-api-request";
import { store } from "../../store/index";
import { getTime } from "../../utils/date";

const getBillsUrl = process.env.REACT_APP_BASE_API + "api/bill/all";
const getBillsByProjectUrl = process.env.REACT_APP_BASE_API + "api/bill/all/{userId}/{projectId}";

const getBillUrl = process.env.REACT_APP_BASE_API + "api/bill";

const removeBillUrl = process.env.REACT_APP_BASE_API + "api/bill";
const addBillUrl = process.env.REACT_APP_BASE_API + "api/bill";
const updateBillUrl = process.env.REACT_APP_BASE_API + "api/bill";

export const sortData = (array) => {
  return array.sort((a, b) => {
    if (a.ref < b.ref) return 1;
    if (a.ref > b.ref) return -1;

    return 0;
  });
};

export const getAll = async () => {
  const bills = await Get(
    getBillsUrl + "/" + store.getState().authentication.user.id
  );
  return sortData(bills.map((bill) => billingDtoToBillingView(bill)));
};

export const getByProject = async (projectId) => {
  const bills = await Get(
    getBillsByProjectUrl.replace("{userId}", store.getState().authentication.user.id).replace("{projectId}", projectId)
  );
  return sortData(bills.map((bill) => billingDtoToBillingView(bill)));
};


export const get = async (id) => {
  return billingDtoToBillingView(await Get(getBillUrl + "/" + store.getState().authentication.user.id + "/" + id));
};

export const add = async (bill) => {
  if (bill["userId"] === undefined)
    bill["userId"] = store.getState().authentication.user.id;

  const response = await Post(addBillUrl, billingViewToBillingDto(bill));
  return billingDtoToBillingView(response);
};

export const update = async (bill) => {
  if (bill["userId"] === undefined)
    bill["userId"] = store.getState().authentication.user.id;

  const response = await Put(updateBillUrl, billingViewToBillingDto(bill));
  return billingDtoToBillingView(response);
};

export const remove = async (id) => {
  return await Delete(removeBillUrl + "/" + store.getState().authentication.user.id + "/" + id);
};

export const lookupData = (bills) => {
  let lookup = {};
  bills.forEach((bill) => (lookup[bill.id] = bill.ref));

  return lookup;
};

const billingDtoToBillingView = (item) => {
  return {
    ...item,
    // serviceExecutionDate: formatDate(item.serviceExecutionDate),
    // creationDate: formatDate(item.creationDate),
    // paimentDate: formatDate(item.paimentDate),
    // penaltyDate: formatDate(item.penaltyDate),
  };
};

const billingViewToBillingDto = (item) => {
  return {
    ...item,
    serviceExecutionDate: getTime(item.serviceExecutionDate),
    creationDate: getTime(item.creationDate),
    paimentDate: getTime(item.paimentDate),
    penaltyDate: getTime(item.penaltyDate),
    collectDate: item.collectDate ? getTime(item.collectDate) : 0,
  };
};
