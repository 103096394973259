import { DELETE_SKILL } from "../../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setSkills } from "../../../actions/cv/skill";
import { remove } from "../../../../repositories/cv/skill";
import { setNotification } from "../../../actions/common";

const deleteSkill = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_SKILL),
    switchMap(({ payload }) =>
      from(remove(payload)).pipe(
        flatMap(() =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully deleted"),
            }),
            setSkills(removeSkill([...state$.value.dataSet.cv.skills], payload))
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const removeSkill = (skills, id) => {
  return skills.filter((skill) => skill.id !== id);
};

export default deleteSkill;
