import { ADD_PROJECT } from "../../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { setProjects } from "../../../actions/reporting/project";
import { add } from "../../../../repositories/project";
import { setNotification } from "../../../actions/common";

const addProject = (action$, state$) =>
  action$.pipe(
    ofType(ADD_PROJECT),
    switchMap(({ payload }) =>
      from(add(payload)).pipe(
        flatMap((resp) =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("successfully added"),
            }),
            setProjects(
              addProjects([...state$.value.dataSet.reporting.projects], resp)
            )
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

const addProjects = (projects, item) => {
  projects.push(item);
  return projects;
};

export default addProject;
