import { Get, Post, Put, Delete } from "../../utils/base-api-request";
import { store } from "../../store/index";

const getStudiesUrl = process.env.REACT_APP_BASE_API + "api/study/all";
const getStudyUrl = process.env.REACT_APP_BASE_API + "api/study";
const removeStudyUrl = process.env.REACT_APP_BASE_API + "api/study";
const addStudyUrl = process.env.REACT_APP_BASE_API + "api/study";
const updateStudyUrl = process.env.REACT_APP_BASE_API + "api/study";

export const sortData = (studies) => {
  return studies.sort((a, b) => {
    if (a.to === b.to) {
      return a.from === b.from || a.from > b.from ? -1 : 1;
    } else {
      return a.to > b.to ? -1 : 1;
    }
  });
};

export const getAll = async () => {
  const response = await Get(
    getStudiesUrl + "/" + store.getState().authentication.user.id
  );
  return sortData(response);
};

export const get = async (id) => {
  return await Get(getStudyUrl + "/" + id);
};

export const add = async (study) => {
  if (study["userId"] === undefined)
    study["userId"] = store.getState().authentication.user.id;

  return await Post(addStudyUrl, study);
};

export const update = async (study) => {
  if (study["userId"] === undefined)
    study["userId"] = store.getState().authentication.user.id;

  return await Put(updateStudyUrl, study);
};

export const remove = async (id) => {
  return await Delete(removeStudyUrl + "/" + id);
};

export const lookupData = (studies) => {
  let lookup = {};
  studies.forEach((study) => (lookup[study.id] = study.name));

  return lookup;
};
