import { Get, Post, Put, Delete } from "../../utils/base-api-request";
import { store } from "../../store/index";

const getCertificationsUrl =
  process.env.REACT_APP_BASE_API + "api/certification/all";
const getCertificationUrl =
  process.env.REACT_APP_BASE_API + "api/certification";
const removeCertificationUrl =
  process.env.REACT_APP_BASE_API + "api/certification";
const addCertificationUrl =
  process.env.REACT_APP_BASE_API + "api/certification";
const updateCertificationUrl =
  process.env.REACT_APP_BASE_API + "api/certification";

export const sortData = (certifications) => {
  return certifications.sort((a, b) => {
    if (a.startYear === b.startYear) {
      return a.startMonth === b.startMonth || a.from > b.from ? -1 : 1;
    } else {
      return a.startYear > b.startYear ? -1 : 1;
    }
  });
};

export const getAll = async () => {
  const response = await Get(
    getCertificationsUrl + "/" + store.getState().authentication.user.id
  );
  return sortData(response);
};

export const get = async (id) => {
  return await Get(getCertificationUrl + "/" + id);
};

export const add = async (certification) => {
  if (certification["userId"] === undefined)
    certification["userId"] = store.getState().authentication.user.id;

  return await Post(addCertificationUrl, certification);
};

export const update = async (certification) => {
  if (certification["userId"] === undefined)
    certification["userId"] = store.getState().authentication.user.id;

  return await Put(updateCertificationUrl, certification);
};

export const remove = async (id) => {
  return await Delete(removeCertificationUrl + "/" + id);
};

export const lookupData = (certifications) => {
  let lookup = {};
  certifications.forEach(
    (certification) => (lookup[certification.id] = certification.title)
  );

  return lookup;
};
