import {
  FETCH_OPTIONSET,
  SET_OPTIONSET,
  ADD_OPTIONSET,
  UPDATE_OPTIONSET,
  DELETE_OPTIONSET,
} from "../../constants/actions-types";

export const fetchOptionSet = () => ({
  type: FETCH_OPTIONSET,
  payload: {},
});

export const setOptionSet = (data) => ({
  type: SET_OPTIONSET,
  payload: data,
});

export const addOptionSet = (data) => ({
  type: ADD_OPTIONSET,
  payload: data,
});

export const updateOptionSet = (data) => ({
  type: UPDATE_OPTIONSET,
  payload: data,
});
export const deleteOptionSet = (index) => ({
  type: DELETE_OPTIONSET,
  payload: index,
});
