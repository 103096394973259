import { SEND_MAIL } from "../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import i18next from "i18next";
import { sendUserMail } from "../../../repositories/mailing";
import { setNotification } from "../../actions/common";

const sendMail = (action$) =>
  action$.pipe(
    ofType(SEND_MAIL),
    switchMap(({ payload }) =>
      from(sendUserMail(payload)).pipe(
        flatMap((resp) =>
          of(
            setNotification({
              isSuccess: true,
              message: i18next.t("Email sent successfully"),
            })
          )
        ),
        catchError((error) =>
          of(
            setNotification({
              isSuccess: false,
              message: "Error : " + error,
            })
          )
        )
      )
    )
  );

export default sendMail;
