import * as subjects from "../../../constants/subjects";
import * as permissions from "../constants/permissions";
import { store } from "../../../store/index.js";

export const defineRulesForPersonalInfo = (can, roles) => {
  if ([permissions.ALL].some((role) => roles.includes(role))) {
    can(permissions.MANAGE, subjects.PERSONALINFO);
  } else {
    roles.forEach((perm) => {
      can(perm, subjects.PERSONALINFO);
    });
  }
};

export const canUpdatePersonalInfo = () =>
  store
    .getState()
    .authentication.user.ability.can(
      permissions.PERSONAL_INFO_UPDATE,
      subjects.PERSONALINFO
    );
