import { combineReducers } from "redux";
import common from "./common/reducer";
import notification from "./notification//reducer";
import authentication from "./authentication/reducer";
import configuration from "./configuration/reducer";
import dataSet from "./dataSet/reducer";

const rootReducer = combineReducers({
  common,
  notification,
  authentication,
  configuration,
  dataSet,
});

export default rootReducer;
